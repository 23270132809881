<template>
	<el-dialog :visible.sync="show" :show-close="false" :close-on-click-modal="false" :before-close="handleClose">
		<div slot="title">
			<div class="titleLeft">
				{{i18n('75')}}
			</div>
			<div class="titleRight" @click="handleClose">
				<img src="@/assets/close.png" alt="">
			</div>
		</div>
		<div class="info" v-if="data">
			<div class="left">
				<img :src="data.base.cover" alt="">
			</div>
			<div class="right">
				<div class="top">
					<div class="name">
						{{lang=='zh'?data.base.zh_name:lang=='en'?data.base.en_name:data.base.vi_name}}
					</div>
					<div class="dis over">
						{{lang=='zh'?data.base.zh_selling_point:lang=='en'?data.base.en_selling_point:data.base.vi_selling_point}}
					</div>
				</div>
				<div class="bot">
					<div class="text">
						{{i18n('76')}}
					</div>
					<div style="display: flex;align-items: baseline;">
						<div class="dw">
							₱
						</div>
						<div class="number">
							{{data.skuAc.price}}
						</div>
						<div class="gg">
							/{{lang=='zh'?data.skuAc.zh_name:lang=='en'?data.skuAc.en_name:data.skuAc.vi_name}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ggList">
			<div class="left">
				{{i18n('77')}}
			</div>
			<div class="right">
				<div :class="item.id==data.skuAc.id?'item itemAc':'item'" v-for="(item,index) in data.sku" :key='index'
					@click="skuAcChange(index)">
					{{lang=='zh'?item.zh_name:lang=='en'?item.en_name:item.vi_name}}
				</div>
			</div>
		</div>
		<div class="sl">
			<div class="left">
				{{i18n('78')}}
			</div>
			<div class="jjcz">
				<div class="jian" @click="numberChange(2)">
					<img src="@/assets/jian.png" alt="" mode="widthFix">
				</div>
				<div class="num">
					<input type="number" v-model="Count" @change="numberChange(3)">
				</div>
				<div class="jia" @click="numberChange(1)">
					<img src="@/assets/jia.png" alt="" mode="widthFix">
				</div>
			</div>
		</div>
		<div class="but" @click="sumb">
			{{i18n('79')}}
		</div>
	</el-dialog>
</template>

<script>
	export default {
		name: "addCartPopup",
		components: {

		},
		data() {
			return {
				data: false,
				stock: [],
				Count: 1
			}
		},
		methods: {
			async sumb() {
				let params = JSON.parse(JSON.stringify(this.data))
				params.Count = this.Count
				await this.$store.dispatch("cart/addCart", params)
				this.$store.commit('cart/setAddCartPopup', false)
			},
			numberChange(type) {
				if (type == 1) {
					//加
					let num = this.Count + 1
					if (num > this.purchase_limit) {
						this.$message.error(this.i18n('80') + this.purchase_limit)
					} else if (this.data.skuAc.stock >= num) {
						this.Count = num
					} else {
						this.$message.error(this.i18n('81'))
					}
				} else if (type == 2) {
					if (this.Count > 1) {
						this.Count--
					}
				} else if (type == 3) {
					if (this.Count > this.purchase_limit) {
						this.$message.error(this.i18n('80') + this.purchase_limit)
						this.Count = this.purchase_limit
					} else if (this.data.skuAc.stock < this.Count) {
						this.$message.error(this.i18n('81'))
						this.Count = this.data.skuAc.stock
					}
				}
			},
			handleClose() {
				this.$store.commit('cart/setAddCartPopup', false)
			},
			skuAcChange(index) {
				if (this.stock[index] <= 0) {
					this.$message.error(this.i18n('81'))
					return false
				}
				this.data.skuAc = this.data.sku[index]
				let spData = this.$store.state.cart.commodity
				let zd = false
				spData.map(item => {
					if (item.skuAc.id == this.data.skuAc.id) {
						zd = true
						if (this.stock[index] < item.Count) {
							this.Count = this.stock[index]
						} else {
							this.Count = item.Count
						}

					}
				})
				if (!zd) {
					this.Count = 1
				}
				this.$forceUpdate()
			},
			async getStock(data) {
				let stock = []
				data.sku.map((item, index) => {
					if (item.stock_type == 0) {
						stock.push(parseInt(data.base.stock / item.quantity))
						data.sku[index].stock = (parseInt(data.base.stock / item.quantity))
					} else {
						stock.push(parseInt(data.base.stock / (item.quantity * data.unit_info.proportion)))
						data.sku[index].stock = (parseInt(data.base.stock / (item.quantity * data.unit_info
							.proportion)))
					}
				})

				return stock
			},
			async open(data) {
				let stock = await this.getStock(data)
				this.stock = stock
				this.data = data
				this.Count = 1
				this.data.skuAc = false
				let indexs = 0
				this.stock.map((item, index) => {
					if (item > 0 && !this.data.skuAc) {
						this.data.skuAc = this.data.sku[index]
						indexs=index
					}
				})
				this.skuAcChange(indexs)
			},
		},
		computed: {
			show() {
				if (this.$store.state.cart.addCartPopup) {
					this.open(this.$store.state.cart.seleAddCartData)
				}
				return this.$store.state.cart.addCartPopup
			},
			purchase_limit() {
				let purchase_limit = 10000000000
				if (this.data) {
					if (this.data.base.purchase_limit > 0) {
						purchase_limit = this.data.base.purchase_limit
					}
				}
				return purchase_limit
			},
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.but{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		text-align: center;
		line-height: 44px;
		cursor: pointer;
	}
	.sl {
		display: flex;
		margin-bottom: 50rpx;
	}

	.sl .left {
		width: 70px;
		opacity: 0.6;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
	}

	.num {
		width: 72px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(86, 171, 123, 0.10);
	}

	.num input {
		width: 72px;
		height: 44px;
		background: none;
		border: none;
		text-align: center;
		line-height: 44px;
		font-family: PingFangSC-Regular;
		font-size: 22px;
		color: #1D2129;
		font-weight: 400;
	}

	.jian,
	.jia {
		width: 36px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.jian img,
	.jia img {
		width: 16px;
		height: 16px;
		display: block;
	}

	.jian {
		border-right: 1px solid rgba(193, 193, 193, 1);
	}

	.jia {
		border-left: 1px solid rgba(193, 193, 193, 1);
	}

	.jjcz {
		width: 144px;
		height: 44px;
		background: #FFFFFF;
		border: 1px solid rgba(193, 193, 193, 1);
		border-radius: 4px;
		display: flex;
		margin-bottom: 50px;
	}

	.ggList {
		display: flex;
		margin-top: 32px;
		margin-bottom: 14px;
	}

	.ggList .left {
		width: 70px;
		opacity: 0.6;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		font-weight: 400;
		/* line-height: 32px; */
	}

	.ggList .right {
		display: flex;
		flex-wrap: wrap;
	}

	.ggList .right .item {
		padding: 0 36px;
		min-width: 120px;
		height: 32px;
		background: #FFFFFF;
		border: 1px solid rgba(183, 183, 183, 1);
		border-radius: 4px;
		text-align: center;
		line-height: 32px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #1D2129;
		text-align: center;
		font-weight: 400;
		margin-bottom: 12px;
		margin-right: 12px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.ggList .right .itemAc {
		border: 1px solid rgba(86, 171, 123, 1);
		color: #56AB7B;
	}

	.info {
		display: flex;
	}

	.info .left {
		margin-right: 24px;
		position: relative;
	}

	.info .left img {
		width: 140px;
		height: 140px;
		display: block;
	}

	.info .right {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.info .right .top .name {
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #1D2129;
		line-height: 18px;
		font-weight: 500;
		margin-bottom: 8px;
	}

	.info .right .top .dis {
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #56AB7B;
		font-weight: 400;
	}

	.info .right .bot {
		width: 434px;
		height: 48px;
		background: rgba(86, 171, 123, 0.08);
		display: flex;
		align-items: center;
		padding: 0 10px;
		box-sizing: border-box;
	}

	.info .right .bot .text {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		opacity: 0.6;
		font-weight: 400;
		margin-right: 24px;
	}

	.info .right .bot .dw {
		font-family: Helvetica;
		font-size: 22px;
		color: #F23725;
		font-weight: 400;
	}

	.info .right .bot .number {
		font-family: PingFangSC-Medium;
		font-size: 30px;
		color: #F23725;
		font-weight: 500;
	}

	.info .right .bot .gg {
		opacity: 0.5;
		font-family: PingFangSC-Regular;
		font-size: 20px;
		color: #1D2129;
		font-weight: 400;
	}

	>>>.el-dialog__body {
		padding: 0 32px 36px 32px;
	}

	>>>.el-dialog {
		width: 662px;
		background: #FFFFFF;
		border-radius: 20px;
	}

	>>>.el-dialog__header {
		padding: 0 32px;
	}

	>>>.el-dialog__header div {
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	>>>.el-dialog__header .titleLeft {
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #000000;
		letter-spacing: 0;
		font-weight: 500;
	}

	>>>.el-dialog__header .titleRight {
		width: 24px;
		height: 24px;
		background: #F2F3F5;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		cursor: pointer;
	}

	>>>.el-dialog__header .titleRight img {
		width: 12px;
		height: 12px;
		display: block;
	}
</style>
