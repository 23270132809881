export default {
	'01':'登陆/注册',
	'02':'首页',
	'03':'手机格式不正确',
	'04':'请输入手机号码',
	'05':'验证码不能为空',
	'06':'请输入验证码',
	'07':'密码不能为空',
	'08':'请输入密码',
	'09':'获取验证码',
	'10':'验证码',
	'11':'密码',
	'12':'设置密码',
	'13':'邀请码',
	'14':'',
	'15':'选填',
	'16':'+63',
	'17':'用户协议',
	'18':'重置密码',
	'19':'商品详情',
	'20':'商品列表',
	'21':'个人资料',
	'22':'优惠券',
	'23':'地址管理',
	'24':'美菜问答',
	'25':'充值中心',
	'26':'邀请好友',
	'27':'关于我们',
	'28':'客服',
	'29':'购物车',
	'30':'下单',
	'31':'支付',
	'32':'下单结果',
	'33':'我的订单',
	'34':'订单详情',
	'35':'APP下载',
	'36':'供应商',
	'37':'位置',
	'38':'充值',
	'39':'代理',
	'40':'退出登陆',
	'41':'中文',
	'42':'英文',
	'43':'越南文',
	'44':'新建地址',
	'45':'编辑地址',
	'46':'收件人',
	'47':'电话',
	'48':'城市',
	'49':'请选择',
	'50':'详细地址',
	'51':'家',
	'52':'公司',
	'53':'其他',
	'54':'设为默认地址',
	'55':'取消',
	'56':'确定',
	'57':'选择地址',
	'58':'呜呼~没有设置收获地址',
	'59':'名称不能为空',
	'60':'收获城市不能为空',
	'61':'详细地址最少5个字符',
	'62':'手机号码格式错误',
	'63':'编辑成功',
	'64':'新增成功',
	'65':'收货人',
	'66':'收货地址',
	'67':'编辑',
	'68':'政策',
	'69':'鲜果',
	'70':'搜索',
	'71':'历史搜索',
	'72':'我的购物车',
	'73':'通告',
	'74':'退出登录',
	'75':'选择产品规格',
	'76':'价格',
	'77':'规格',
	'78':'数量',
	'79':'加入购物车',
	'80':'已达到限购数量',
	'81':'库存不足',
	'82':'分类',
	'83':'精选好货',
	'84':'特价促销',
	'85':'预定商品',
	'86':'充值有送',
	'87':'邀请好友，领取佣金',
	'88':'无门槛',
	'89':'满',
	'90':'减',
	'91':'已使用',
	'92':'已失效',
	'93':'即将过期',
	'94':'已过期',
	'95':'天后过期',
	'96':'查看详情',
	'97':'收起',
	'98':'选择城市',
	'99':'配送时间',
	'100':'即时配送',
	'101':'预定包裹',
	'102':'立即配送',
	'103':'和包裹1相同时间',
	'104':'即时配送没有选择时间',
	'105':'预定商品没有选择时间',
	'106':'今天',
	'107':'明天',
	'108':'后天',
	'109':'可用优惠券',
	'110':'使用优惠券仅支持线上支付',
	'111':'呜呼~没有对应的优惠券',
	'112':'知道了',
	'113':'没有选择收获地址',
	'114':'订单摘要',
	'115':'合计',
	'116':'提交订单',
	'117':'是否删除产品',
	'118':'我常卖',
	'119':'呜呼~没有商品',
	'120':'张可用',
	'121':'查看',
	'122':'请选择地址',
	'123':'没有选择地址信息',
	'124':'切换',
	'125':'全选',
	'126':'商品名称',
	'127':'单价',
	'128':'小计',
	'129':'操作',
	'130':'还有',
	'131':'件商品被折叠',
	'132':'无效商品',
	'133':'批量删除',
	'134':'购物车空空如也~',
	'135':'全部结果',
	'136':'销量',
	'137':'标签筛选',
	'138':'重置',
	'139':'搜索结果',
	'140':'呜呼~没有您想要的商品',
	'141':'今日推荐',
	'142':'预定',
	'143':'到货通知',
	'144':'商品到货后，将第一时间通知您',
	'145':'加购成功',
	'146':'售后无忧',
	'147':'新鲜自然',
	'148':'质量保证',
	'149':'快速交货',
	'150':'大家都喜欢',
	'151':'查看更多',
	'152':'近日特价',
	'153':'隔日配送',
	'154':'登陆',
	'155':'进入注册',
	'156':'快捷登陆',
	'157':'密码登陆',
	'158':'忘记密码',
	'159':'注册',
	'160':'已有账号，去登录',
	'161':'注册',
	'162':'美菜生鲜用户协议',
	'163':'注册即表示您同意',
	'164':'新用户注册，首单减免',
	'165':'下单时自动扣除，只限线上支付订单',
	'166':'地址切换',
	'167':'配送费',
	'168':'优惠金额',
	'169':'总计',
	'170':'去结算',
	'171':'优惠码',
	'172':'请输入优惠码',
	'173':'兑换',
	'174':'重新选择',
	'175':'选择优惠券',
	'176':'选择时间',
	'177':'备注',
	'178':'请填写您需要备注的话',
	'179':'支付信息',
	'180':'订单编号',
	'181':'支付方式',
	'182':'免运费',
	'183':'重新支付',
	'184':'申请售后',
	'185':'请下载手机端前往申请售后',
	'186':'待付款',
	'187':'待发货',
	'188':'待收货',
	'189':'已完成',
	'190':'售后中',
	'191':'订单取消',
	'192':'售后完成',
	'193':'编辑备注',
	'194':'收获信息',
	'195':'取消订单',
	'196':'收获地址',
	'197':'变更地址',
	'198':'修改备注',
	'199':'变更时间',
	'200':'支付宝',
	'201':'微信',
	'202':'货到付款',
	'203':'余额支付',
	'204':'支付成功',
	'205':'下单失败',
	'206':'美菜将会尽快按照约定时间配送',
	'207':'订单将于15分钟之后自动关闭',
	'208':'去逛逛',
	'209':'配送时间没有选择',
	'210':'选择付款方式',
	'211':'实付',
	'212':'美菜余额',
	'213':'立即付款',
	'214':'是否支付完成?',
	'215':'完成',
	'216':'未完成',
	'217':'余额不足',
	'218':'新密码',
	'219':'请输入新密码',
	'220':'密码不能为空',
	'221':'再次输入',
	'222':'两次输入的密码不一致',
	'223':'修改成功',
	'224':'验证手机',
	'225':'下一步',
	'226':'验证手机号',
	'227':'修改完成',
	'228':'还没有地址哦',
	'229':'添加地址',
	'230':'余额',
	'231':'消息通知',
	'232':'可使用',
	'233':'更换手机号码',
	'234':'绑定手机号',
	'235':'更换成功',
	'236':'更换头像',
	'237':'头像',
	'238':'更换',
	'239':'昵称',
	'240':'生日',
	'241':'邮箱',
	'242':'选择日期',
	'243':'性别',
	'244':'手机',
	'245':'保存',
	'246':'未设置',
	'247':'男',
	'248':'女',
	'249':'保密',
	'250':'邀请朋友享受5%现金返还',
	'251':'您的钱包余额',
	'252':'邀请朋友享受5%的现金回报。你邀请的朋友越多，你的收入就越多。收入可用于扣除订单金额。',
	'253':'成为代理人',
	'254':'总收益',
	'255':'昨天',
	'256':'本月',
	'257':'1、如何成为美菜推广员？',
	'258':'1.登陆美菜小程序/APP，人人都是美菜的推广员。',
	'259':'2.推广员可以邀请新朋友加入美菜小程序/APP，只要你的朋友在美菜消费，推广员就可以终身获得收益；',
	'260':'2、如何邀请好友？',
	'261':'1.微信小程序：',
	'262':'①点击邀请好友→保存邀请二维码→发送给朋友扫码进入小程序→完成注册；',
	'263':'②点击邀请好友→复制邀请码→发送给朋友→进入小程序填写邀请码→完成注册；',
	'264':'点击邀请好友→复制邀请码→发送给朋友→进入APP填写邀请码→完成注册；',
	'265':'3.点击“我的客户”，可以查看成功邀请的朋友。',
	'266':'3、美菜推广员的等级和收益？',
	'267':'1.推广员可获得客户消费金额的0.5%的奖励金，并自动存入余额；',
	'268':'2.当客户订单完成时，奖励金自动结算存入账户，明细可在“收益”里查看；',
	'269':'邀请好友获取',
	'270':'实时',
	'271':'交易完成即时到账',
	'272':'下载共享奖励',
	'273':'我的收入',
	'274':'邀请朋友获得现金奖励',
	'275':'我的客户',
	'276':'朋友昵称',
	'277':'释放时间',
	'278':'呜呼~暂时没有好友',
	'279':'现金返还金额',
	'280':'呜呼~暂时没有收益',
	'281':'消息中心',
	'282':'订单消息',
	'283':'优惠消息',
	'284':'呜呼~暂时没有消息',
	'285':'下单时间',
	'286':'订单号',
	'287':'自动关闭订单',
	'288':'共',
	'289':'件商品',
	'290':'去支付',
	'291':'分',
	'292':'秒',
	'293':'全部订单',
	'294':'暂时没有订单哦',
	'295':'开始购物',
	'296':'记录详情',
	'297':'交易时间',
	'298':'支付编号',
	'299':'退款',	
	'300':'订单有疑问？',	
	'301':'联系客服',	
	'302':'充值信息',	
	'303':'钱包余额',	
	'304':'选择余额',	
	'305':'赠送',	
	'306':'自定义',	
	'307':'请输入您要充值的金额',	
	'308':'您想如何付款？',	
	'309':'充值记录',	
	'310':'提示',	
	'311':'如果您在绑定过程中有任何问题，请联系在线客户服务。',	
	'312':'更改绑定的手机。如果原始手机号码已禁用且无法获取验证代码请联系在线客户服务。',
	'313':'最少1P',
	'314':'是否充值完成?',
	'315':'消费记录',
	'316':'时间',
	'317':'入账方式',
	'318':'状态',
	'319':'金额',
	'320':'订单消费',
	'321':'提现',
	'322':'佣金',
	'323':'优惠券抵用',
	'324':'服务费',
	'325':'成功',
	'326':'订单已取消',
	'327':'呜呼~暂时没有订单哦',
	'328':'扫码下载美菜生鲜APP',
	'329':'或到APP商城搜索',
	'330':'微信扫码使用美菜生鲜小程序',
	'331':'或到A微信搜索',
	'332':'美菜欢迎正直、规范、优质的产品供应商伙伴加入！',
	'333':'可提供场地，冷库，销售平台，骑手配送，订单收款，Grab，Foodpanda，Shopee,Lazada等开店运营等便利条件！',
	'334':'合作内容',
	'335':'生鲜/水果/肉/海鲜/冷冻食品/零食/蛋糕/卤味/沙拉/小吃/饮品/鲜花/母婴/美妆护理/日用清洁/3C电子产品/其他',
	'336':'Process',
	'337':'美菜生鲜成立于2021年，旗下拥有多个自营的前置+门店，自有独立开发APP及网站，美菜目标成为菲律宾最大的直营电商平台。现诚招各供应商合作，美菜欢迎正直、规范、优质的产品供应商伙伴加入！对优质供应商可提供场地，冷库，销售平台，骑手配送，订单收款，Grab，Foodpanda，Shopee,Lazada等开店运营等便利条件！',
	'338':'我们的合作伙伴平台',
	'339':'余额支付 发物流',
	'340':'余额支付 到店自取',
	'341':'到店自取付现金',
	'342':'货到付款发物流',
	
	
	
	
	"400": "请求参数错误",
	"401": "会话超时",
	"403": "无权访问",
	"500": "服务器出错", //系统性错误，用于调试，一般为第三方错误，数据库错误，表单验证错误，RPC服务器超时或中断等错误，前端根据情况处理。
	
	//用户错误
	"4001": "用户账户不存在",
	"4002": "用户账户被锁定",
	"4003": "用户设置记录不存在",
	"4004": "用户状态未验证或审核或被锁定",
	"4005": "用户不存在",
	"4006": "用户已存在",
	"4007": "用户账户无效",
	"4008": "用户token为空",
	"4009": "用户暂时不允许登录",
	"4010": "Duplicate entry",
	"4011": "用户密码错误",
	"4012": "验证码为空",
	"4013": "商户未提交过认证资料",
	"4014": "用户token无效",
	"4015": "用户设置信息已存在",
	"4016": "验证码过期",
	"4017": "邮件发送错误",
	"4018": "验证码无效",
	"4019": "用户token过期",
	"4020": "邀请码不存在",
	"4021": "登录失败",
	"4022": "token失效",
	"4023": "验证码错误",
	"4024": "用户不存在或密码错误",
	"4025": "账户已删除",
	"4026": "邮箱已被注册",
	"4027": "账号已注册，请登录",
	"4028": "注册失败",
	"4029": "邮箱格式不正确",
	"4030": "密码长度不能少于6位",
	"4031": "昵称必须填写",
	"4032": "手机号码已被注册",
	"4033": "手机号码格式错误",
	"4034": "请勿频繁发送短信",
	"4035": "手机号未被注册,请注册后再登录",
	"4036": "绑定失败",
	"4037": "密码修改失败",
	"4038": "收货名称长度不能大于7个汉字", //英文改为20个字符
	"4039": "请选择收货的城市",
	"4040": "请填写完整的详细收获地址",
	"4041": "必须选择收货城市",
	"4042": "收货地址添加失败",
	"4043": "没有设置收货地址",
	"4044": "修改购物车数量失败",
	"4045": "添加购物车失败",
	"4046": "删除购物车失败",
	"4047": "代理升级失败",
	"4048": "充值失败",
	"4049": "未获取到运费",
	"4050": "该城市可能未在配送范围",
	"4051": "该优惠券不能用于此订单",
	"4052": "消费金额达不到优惠券使用条件",
	"4053": "该订单不在配送范围",
	"4054": "价格不一致，请重新提交",
	"4055": "余额不足以支付该订单",
	"4056": "该优惠券不存在",
	"4057": "请选择正确的配送时间",
	"4058": "申请售后失败，请重试",
	"4059": "请选择正确的支付方式",
	"4060": "该用户余额已不足以见扣该金额",
	"4061": "请选择类型",
	"4062": "即时配送订单优惠券不存在",
	"4063": "预定订单优惠券不存在",
	"4064": "即时配送订单消费金额达不到优惠券使用条件",
	"4065": "预定订单消费金额达不到优惠券使用条件",
	"4066": "该优惠券不能用于即时配送订单",
	"4067": "该优惠券不能用于预订订单",
	
	
	//
	"5001": "验证码在请求时间段达到最大限制",
	"5002": "验证码仍在请求时间间隔内",
	"5003": "新增失败",
	"5004": "更新失败",
	"5005": "删除失败",
	"5006": "无权限访问",
	"5007": "无权限操作",
	"5008": "参数错误",
	"5009": "数据不存在",
	"5010": "验证码生成失败",
	"5011": "菜单ID不能为空",
	"5012": "Role Id不能为空",
	"5013": "没有任何可访问权限",
	"5014": "操作失败",
	"5015": "状态更改失败",
	"5016": "商品不存在或以下架",
	"5017": "数据已存在",
	"5018": "验证码发送失败",
	
	
	//order
	"6001": "交易号不存在",
	"6002": "订单过期",
	"6003": "评论标签已存在",
	"6004": "商品sku-code已存在系统",
	"6005": "订单事务号为空",
	"6006": "支付时间过期",
	"6007": "订单正在支付中",
	"6008": "订单状态无效",
	"6009": "订单已完成支付",
	"6010": "商品价格版本不存在",
	"6011": "订单已完成支付",
	"6012": "外部交易号为空",
	"6013": "交易订单不匹配当前用户",
	"6014": "订单状态被锁定",
	"6015": "商品sku-code不存在",
	"6017": "商品库存不够",
	"6018": "用户余额不足",
	"6019": "金额格式解析错误",
	"6020": "订单已存在",
	"6021": "ID为空",
	"6022": "用户订单不存在",
	"6023": "物流记录已存在",
	"6024": "事务执行失败",
	"6025": "物流记录不存在",
	"6026": "用户物流收货地址不存在",
	"6027": "评论标签不存在",
	"6028": "交易单号正在支付中",
	"6029": "订单创建失败",
	"6030": "余额支付失败",
	"6031": "订单取消失败",
	"6032": "订单失败，请稍后再试",
	"6033": "库存不足，请稍后再试",
	"6034": "改订单不可取消",
	"6035": "该订单已申请售后，请耐心等待处理结果",
	"6036": "该订单使用了优惠券，不支持货到付款",
	"6037": "订单已超过支付时间",
	"6038": "订单已取消，无法重新支付",
	"6039": "请选择您要购买的商品",
	"6040": "该状态下无法减免运费",
	"6041": "该订单已分配骑手",
	"6042": "改订单已收款",
	"6043": "收款金额错误",
	"6044": "优惠码使用失败",
	//7000
	"7000": "文件上传失败",
	"7001": "文件创建失败",
	"7002": "文件保存失败"
}