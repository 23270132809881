import fetch from '@/utils/fetch.js';

//发送验证码
/**
 * 
 * @param {{
  "type": 0, 0注册绑定，1登陆
  "phone": "string",
  "email": "string"
}} 
 */
export function sendCode(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/send/code",
		method: 'POST',
		data
	})
}
//邮箱注册

export function registerEmail(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/register/email",
		method: 'POST',
		data
	})
}
//绑定电话号码
/**
 * 
 * @param {{
  "phone": "string",
  "c0de": "string"
}} 
 */
export function bindPhone(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/bind/phone",
		method: 'POST',
		data
	})
}
//手机注册
export function registerPhone(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/register/phone",
		method: 'POST',
		data
	})
}
//手机登陆
export function loginPhone(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/login/phone",
		method: 'POST',
		data
	})
}
//邮箱登陆
export function loginEmail(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/login/email",
		method: 'POST',
		data
	})
}
//重置密码验证手机号
export function checkPhone(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/rest/check/phone",
		method: 'POST',
		data
	})
}
//重置密码
export function restPassword(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/rest/password",
		method: 'POST',
		data
	})
}
//获取用户信息
export function baseInfo(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/base/info",
		method: 'POST',
		data
	})
}
//获取用户余额
export function getBalance(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/base/balance",
		method: 'POST',
		data
	})
}
//获取头像列表
export function avatarList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/avatar/list",
		method: 'POST',
		data
	})
}
//修改用户基本信息
export function update(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/base/update",
		method: 'POST',
		data
	})
}
//小程序二维码
export function wxQrcode(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/wx/qrcode",
		method: 'POST',
		data
	})
}
//绑定邮箱
export function bindEmail(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/bind/email",
		method: 'POST',
		data
	})
}

//密码登陆
export function loginPassword(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/login/password",
		method: 'POST',
		data
	})
}
//密码注册
export function registerPassword(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/user/register/password",
		method: 'POST',
		data
	})
}

//获取周边位置
export function positionSearch(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/v2/user/position/nearby/search",
		method: 'POST',
		data
	})
}