import {storehouseCityList} from "@/api/storehouse.js"
import {
	setCookies,
	getCookies
} from "@/utils/cookies.js"
const state = {
	area:JSON.parse(getCookies('area')) || false,
	seleLoca:JSON.parse(getCookies('seleLoca')) || false,
	addressData:JSON.parse(getCookies('addressData')) || false,
	addressDataRows:JSON.parse(getCookies('addressDataRows')) || false,
	delivery:false,
}

const getters = {

}

const mutations = {
	setAddressData(state, data) {
		setCookies('addressData',JSON.stringify(data))
		state.addressData = data
	},
	setAddressDataRows(state, data) {
		setCookies('addressDataRows',JSON.stringify(data))
		state.addressDataRows = data
	},
	setArea(state, data) {
		state.area=data
		setCookies('area',JSON.stringify(data))
	},
	setSeleLoca(state, data) {
		state.seleLoca=data
		setCookies('seleLoca',JSON.stringify(data))
	},
	setDelivery(state, data) {
		state.delivery=data
	},
	deleSeleLoca(state) {
		state.seleLoca=false
	}
}

const actions = {
	async storehouseCityList({
		commit
	}) {
		let {data} = await storehouseCityList({})
		commit('setArea', data)
		return data
	},
	async updateAddressData({
		commit,state
	},{address,rows}) {
		commit('setAddressData',address)
		commit('setAddressDataRows',rows)
		let addressArea = state.area
		addressArea.map((item)=>{
			if(item.base.id==address.city_id){
				commit('setSeleLoca',item)
			}
		})
		// return data
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
