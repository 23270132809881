<template>
	<div class="seleCity">
		<el-dialog :visible.sync="dialogTableVisible" custom-class="wap" :show-close="false" :close-on-click-modal="false">
			<div slot="title">{{i18n('98')}}</div>
			<div class="list">
				<div :class="seleData.base.id==item.base.id?'item itemAc':'item'" v-for="(item,index) in city" :key="index" @click="seleCity(item)">
					<span v-if="lang=='zh'">{{item.base.zh_name}}</span>
					<span v-if="lang=='en'">{{item.base.en_name}}</span>
					<span v-if="lang=='vie'">{{item.base.vi_name}}</span>
				</div>
			</div>
			<div class="but" @click="sub">
				{{i18n('56')}}
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "seleCity",
		data() {
			return {
				dialogTableVisible:false,
				seleData:{
					base:{
						id:0
					}
					
				}
			}
		},
		computed:{
			city(){
				console.log(this.$store.state.serviceArea.area)
				return this.$store.state.serviceArea.area
			},
			lang(){
				return this.$store.state.i18n.lang
			}
		},
		methods: {
			seleCity(data){
				this.seleData=data
				this.sub()
			},
			sub(){
				if(this.seleData.base.id!=0){
					this.$store.commit('serviceArea/setSeleLoca',this.seleData)
					this.dialogTableVisible=false
				}
			},
			open(){
				this.seleData=this.$store.state.serviceArea.seleLoca || {
					base:{
						id:0
					}
				}
				setTimeout(()=>{
					this.seleCity(this.city[0])
				},2000)
				
				// this.dialogTableVisible=true
			},
			close(){
				this.dialogTableVisible=false
			}
		},
		mounted() {
	
		},
	};
</script>

<style scoped>
	.but{
		width: 310px;
		height: 44px;
		background: #56AB7B;
		border-radius: 22.5px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		cursor: pointer;
	}
	>>> .wap{
		width: 612px;
		background: #FFFFFF;
		border-radius: 20px;
	}
	>>> .wap .el-dialog__header{
		padding-bottom: 0;
	}
	.list{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
	.list .item{
		padding: 0 30px;
		height: 32px;
		background: rgba(86,171,123, .1);
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: PingFangSC-Medium;
		font-size: 16px;
		color: #000000;
		text-align: center;
		font-weight: 500;
		margin-right: 24px;
		margin-bottom: 20px;
		cursor: pointer;
	}
	.list .item:hover,.list .itemAc{
		background: #56AB7B;
		color: #F8F8F8;
	}
</style>