import fetch from '@/utils/fetch.js';


//获取所有商品分类
export function categoryAll(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/category/all",
		method: 'POST',
		data
	})
}
//获取商品列表
//配送方式 1.24小时达 ，2.预定，3.其它 
//状态：1.待上架，2.上架 ，3.已过期，4.已删除
export function commodityList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/list",
		method: 'POST',
		data
	})
}
//获取首页三个活动商品列表
export function commodityHome(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/recommend",
		method: 'POST',
		data
	})
}
//获取商品详细
export function commodityInfo(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/info",
		method: 'POST',
		data
	})
}
//商品详细页面的推荐 
export function detailRecommend(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/detail/recommend",
		method: 'POST',
		data
	})
}
//获取筛选标签
export function commodityLabel(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/label",
		method: 'POST',
		data
	})
}
//我常买
export function oftenbuy(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/oftenbuy",
		method: 'POST',
		data
	})
}
//删除我常买
export function deleteOftenbuy(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/delete/oftenbuy",
		method: 'POST',
		data
	})
}
//搜索热词
export function hotword(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/hotword",
		method: 'POST',
		data
	})
}
//今日榜单
export function ranking(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/commodity/commodity/today/ranking",
		method: 'POST',
		data
	})
}