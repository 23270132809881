import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import i18n from './module/i18n'
import user from './module/user'
import cart from './module/cart'
import serviceArea from './module/serviceArea'
export default new Vuex.Store({
  state: {
    //
  },
  mutations: {
    //
  },
  actions: {
    //
  },
  modules: {
	i18n,
	user,
	cart,
	serviceArea
  }
})



