import Vue from 'vue'
import Router from 'vue-router'
import zh from "@/lang/zh.js"
import en from "@/lang/en.js"
import {
	getCookies
} from "@/utils/cookies.js"
Vue.use(Router)

// Array.prototype.push.apply(routes,myroutes);//合并自定义路由

const router = new Router({
	mode: 'history',
	routes: [{
		path: '/',
		name: 'view',
		redirect: '/home',
		meta: {
			title: '02',
			keepAlive: true
		},
		component: () => import('@/view/index'),
		children:[
			{
				path: '/home',
				name: 'home',
				meta: {
					title: '02',
					keepAlive: true
				},
				component: () => import('@/view/home/index')
			},
			{
				path: '/category',
				name: 'category',
				meta: {
					title: '20',
					keepAlive: false
				},
				component: () => import('@/view/category/index')
			},
			{
				path: '/commodityDetails',
				name: 'commodityDetails',
				meta: {
					title: '19',
					keepAlive: false
				},
				component: () => import('@/view/commodityDetails/index')
			},
			{
				path: '/userCenter',
				name: 'userCenter',
				meta: {
					title: '21',
					keepAlive: false
				},
				redirect: '/info',
				component: () => import('@/view/userCenter/index'),
				children:[
					{
						path: '/invitation',
						name: 'invitation',
						meta: {
							title: '26',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/invitation/index')
					},
					{
						path: '/myOrder',
						name: 'myOrder',
						meta: {
							title: '33',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/myOrder/index')
					},
					{
						path: '/orderDetails',
						name: 'orderDetails',
						meta: {
							title: '34',
							keepAlive: false
						},
						component: () => import('@/view/order/details/index')
					},
					{
						path: '/myEarning',
						name: 'myEarning',
						meta: {
							title: '26',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/invitation/myEarning')
					},
					{
						path: '/myClient',
						name: 'myClient',
						meta: {
							title: '26',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/invitation/myClient')
					},
					{
						path: '/recharge',
						name: 'recharge',
						meta: {
							title: '25',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/recharge/index')
					},
					{
						path: '/rechargeRecord',
						name: 'rechargeRecord',
						meta: {
							title: '25',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/recharge/record')
					},
					{
						path: '/coupon',
						name: 'coupon',
						meta: {
							title: '22',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/coupon/index')
					},
					{
						path: '/message',
						name: 'message',
						meta: {
							title: '22',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/message/index')
					},
					{
						path: '/address',
						name: 'address',
						meta: {
							title: '23',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/address/index')
					},
					{
						path: '/info',
						name: 'info',
						meta: {
							title: '21',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/info/index')
					},
					{
						path: '/QandA',
						name: 'QandA',
						meta: {
							title: '24',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/QandA/index')
					},
					{
						path: '/customer',
						name: 'customer',
						meta: {
							title: '28',
							keepAlive: false
						},
						component: () => import('@/view/userCenter/customer/index')
					}
				]
			},
			{
				path: '/cart',
				name: 'cart',
				meta: {
					title: '29',
					keepAlive: false
				},
				component: () => import('@/view/cart/index')
			},
			{
				path: '/order',
				name: 'order',
				meta: {
					title: '30',
					keepAlive: false
				},
				component: () => import('@/view/order/index')
			},
			{
				path: '/orderNew',
				name: 'orderNew',
				meta: {
					title: '30',
					keepAlive: false
				},
				component: () => import('@/view/order/indexNew')
			},
			{
				path: '/pay',
				name: 'pay',
				meta: {
					title: '31',
					keepAlive: false
				},
				component: () => import('@/view/order/pay')
			},
			{
				path: '/orderResult',
				name: 'orderResult',
				meta: {
					title: '32',
					keepAlive: false
				},
				component: () => import('@/view/order/result/index')
			}
			
		]
	},{
		path: '/about',
		name: 'about',
		meta: {
			title: '27',
			keepAlive: false
		},
		component: () => import('@/view/about')
	},{
		path: '/download',
		name: 'download',
		meta: {
			title: '35',
			keepAlive: false
		},
		component: () => import('@/view/download')
	},{
		path: '/supplier',
		name: 'supplier',
		meta: {
			title: '36',
			keepAlive: false
		},
		component: () => import('@/view/supplier')
	},{
		path: '/loginRegistration',
		name: 'loginRegistration',
		meta: {
			title: '01',
			keepAlive: true
		},
		component: () => import('@/view/loginRegistration/index')
	}, {
		path: '/policy',
		component: () => import('@/view/policy'),
		name: 'policy',
		meta: {
			title: '17'
		}
	}, {
		path: '/resetPassword',
		component: () => import('@/view/resetPassword/index'),
		name: 'resetPassword',
		meta: {
			title: '18'
		}
	}, ]
})
router.beforeEach((to, from, next) => {
	let lang = getCookies('lang') || 'en'
	let title = ''

	if (to.meta.title) { //如果设置标题，拦截后设置标题
		if (lang == 'zh') {
			title = zh[to.meta.title]
		} else {
			title = en[to.meta.title]
		}
		document.title = title
	}
	next()
})

export default router
