import {
	add,
	cartAll,
	cartDelete
} from "@/api/cart.js"
import {
	getCookies,
	removeCookies
} from "@/utils/cookies.js"
const state = {
	commodity: [],
	forthwith: [],
	reserve: [],
	invalid:[],
	setItems:JSON.parse(getCookies('setItems')) || false,
	createorNumber: {

	},
	number: {
		hours: 0,
		reserve: 0
	},
	addCartPopup: false,
	seleAddCartData:false
}

const getters = {

}

const mutations = {
	setSeleAddCartData(state, data) {
		state.seleAddCartData = data
	},
	setAddCartPopup(state, data) {
		state.addCartPopup = data
	},
	removeSetItems(state) {
		removeCookies('setItems')
		state.setItems = false
	},
	setSetItems(state, data) {
		state.setItems = data
	},
	setCommodity(state, data) {
		// if (data.length > 0) {
		// 	uni.setTabBarBadge({
		// 		index: 2,
		// 		text: String(data.length)
		// 	})
		// } else {
		// 	uni.removeTabBarBadge({
		// 		index: 2,
		// 		success(res) {
					
		// 		},
		// 		fail(res) {
					
		// 		}

		// 	})
		// }
		let createorNumber = {}
		data.map((item) => {
			if (createorNumber['category_id' + item.base.category_id]) {
				createorNumber['category_id' + item.base.category_id] = createorNumber['category_id' +
					item.base.category_id] + 1
			} else {
				createorNumber['category_id' + item.base.category_id] = 1
			}
		})
		state.createorNumber = createorNumber
		state.commodity = data
	},
	setForthwith(state, data) {
		state.forthwith = data
	},
	setReserve(state, data) {
		state.reserve = data
	},
	setInvalid(state, data){
		state.invalid = data
	}
}


const actions = {
	//添加购物车
	async addCart({
		commit,
		state
	}, params) {
		if (!getCookies('token')) {
			// uni.navigateTo({
			// 	url: '/pages/userLogin/login/index'
			// })
			//去登录
			
			return false
		}
		let {
			base,
			skuAc,
			Count
		} = params
		let commodityData = JSON.parse(JSON.stringify(state.commodity))
		let forthwithData = JSON.parse(JSON.stringify(state.forthwith))
		let reserveData = JSON.parse(JSON.stringify(state.reserve))
		let CartId = false
		Count = Number(Count)
		
		commodityData.map((item) => {
			if (item.base.id == base.id && item.skuAc.id == skuAc.id) {
				CartId = item.CartId
			}
		})
		if (!CartId) {
			let data = params
			data.invalid=0
			
				let id = await add({
					commodity_id: Number(base.id),
					sku_id: Number(skuAc.id),
					count: Number(Count) 
				})
				data.CartId = id.cart_id
			

			//更新数据
			data.checked = true
			commodityData.unshift(data)
			if (base.delivery_modeint == 1) {
				forthwithData.unshift(data)
			} else {
				reserveData.unshift(data)
			}
		} else {
			let index = commodityData.findIndex(item => item.CartId == CartId)

			// let count = commodityData[index].Count + 1
			
				await add({
					commodity_id: Number(commodityData[index].base.id),
					sku_id: Number(commodityData[index].skuAc.id),
					count: Number(Count) 
				})
			
			// commodityData[index].Count = commodityData[index].Count + 1
			commodityData[index].Count = Count
			if (commodityData[index].base.delivery_modeint == 1) {
				let ind = forthwithData.findIndex(item => item.CartId == CartId)
				forthwithData[ind].Count = commodityData[index].Count
			} else {
				let ind = reserveData.findIndex(item => item.CartId == CartId)
				reserveData[ind].Count = commodityData[index].Count
			}
		}
		commit('setCommodity', commodityData)
		commit('setForthwith', forthwithData)
		commit('setReserve', reserveData)
		return {
			commodityData,
			forthwithData,
			reserveData
		}
	},

	async cartDelete({
		commit,
		state
	}, params) {
		let commodityData = JSON.parse(JSON.stringify(state.commodity))
		let forthwithData = JSON.parse(JSON.stringify(state.forthwith))
		let reserveData = JSON.parse(JSON.stringify(state.reserve))
		let invalidData = JSON.parse(JSON.stringify(state.invalid))
		let {
			ids
		} = params
		if (getCookies('token')) {
			await cartDelete({
				ids
			})
		}
		ids.map(item => {
			let index = commodityData.findIndex(it => it.CartId == item)
			commodityData.splice(index, 1)
			let ind = forthwithData.findIndex(it => it.CartId == item)
			if(ind!=-1){
				forthwithData.splice(ind, 1)
			}else{
				let inds = reserveData.findIndex(it => it.CartId == item)
				if(inds!=-1){
					reserveData.splice(inds, 1)
				}else{
					let indss = invalidData.findIndex(it => it.CartId == item)
					invalidData.splice(indss, 1)
				}
				
			}
		})
		commit('setCommodity', commodityData)
		commit('setForthwith', forthwithData)
		commit('setReserve', reserveData)
		commit('setInvalid', invalidData)
		return {
			commodityData,
			forthwithData,
			reserveData,
			invalidData
		}
	},
	//更新购物车数量
	async updateCartCount({
		commit,
		state
	}, params) {
		let {
			CartId,
			Count,
			base,
			skuAc,
		} = params
		Count = Number(Count)
		let commodityData = JSON.parse(JSON.stringify(state.commodity))
		let forthwithData = JSON.parse(JSON.stringify(state.forthwith))
		let reserveData = JSON.parse(JSON.stringify(state.reserve))
		let index = commodityData.findIndex(item => item.CartId == CartId)
		// if (index == -1) {
		// 	index = commodityData.findIndex(item => item.Commodity.id == Commodity.id && item.Specifications
		// 		.id == Specifications.id)
		// }
		if (getCookies('token')) {
			// if (!CartId) {
			// 	CartId = commodityData[index].CartId
			// }
			await add({
				commodity_id: Number(base.id),
				sku_id: Number(skuAc.id),
				count: Number(Count) 
			})
		}else{
			// uni.navigateTo({
			// 	url: '/pages/userLogin/login/index'
			// })
			//去登录
			return false
		}
		if (commodityData[index].base.delivery_modeint == 1) {
			let ind = forthwithData.findIndex(item => item.CartId == CartId)
			if (ind == -1) {
				ind = forthwithData.findIndex(item => item.base.id == base.id && item.skuAc
					.id == skuAc.id)
			}
			forthwithData[ind].Count = Count

		} else {
			let ind = reserveData.findIndex(item => item.CartId == CartId)
			if (ind == -1) {
				ind = reserveData.findIndex(item => item.base.id == base.id && item.skuAc
					.id == skuAc.id)
			}
			reserveData[ind].Count = Count
			
		}
		commodityData[index].Count = Count
		
		commit('setCommodity', commodityData)
		commit('setForthwith', forthwithData)
		commit('setReserve', reserveData)
		return {
			commodityData,
			forthwithData,
			reserveData
		}
	},
	//更新选中
	async updateSele({
		commit,
		state
	}, params) {
		let {
			index,
			delivery_modeint
		} = params
		let CartId = false
		let commodityData = JSON.parse(JSON.stringify(state.commodity))
		let forthwithData = JSON.parse(JSON.stringify(state.forthwith))
		let reserveData = JSON.parse(JSON.stringify(state.reserve))
		let invalid = JSON.parse(JSON.stringify(state.invalid))
		let data = false 
		if(delivery_modeint==3){
			//即时配送 跟预定 一起选择
			forthwithData.map(item=>{
				index==-1?item.checked=true:item.checked=false
			})
			reserveData.map(item=>{
				index==-1?item.checked=true:item.checked=false
			})
			invalid.map(item=>{
				index==-1?item.checked=true:item.checked=false
			})
			commodityData.map(item=>{
				index==-1?item.checked=true:item.checked=false
			})
		}else{
			if(delivery_modeint == 1){
				data = forthwithData
			}else if(delivery_modeint == 2){
				data = reserveData
			}else if(delivery_modeint == 4){
				data = invalid
			}
			//index=-1 全选  index=-2取消全选
			if(index==-1 || index==-2){
				data.map(item=>{
					index==-1?item.checked=true:item.checked=false
				})
				commodityData.map(item=>{
					if(item.base.delivery_modeint==delivery_modeint){
						index==-1?item.checked=true:item.checked=false
					}
				})
			}else{
				data[index].checked = !data[index].checked
				CartId = data[index].CartId
				let ind = commodityData.findIndex(item => item.CartId == CartId)
				commodityData[ind].checked = !commodityData[ind].checked
			}
		}
		commit('setCommodity', commodityData)
		commit('setForthwith', forthwithData)
		commit('setReserve', reserveData)
		commit('setInvalid', invalid)
		return {
			commodityData,
			forthwithData,
			reserveData
		}
	},
	async seleAll({
		commit,
		state
	}, params) {
		let {
			checked,
			delivery_modeint
		} = params
		let commodityData = JSON.parse(JSON.stringify(state.commodity))
		let forthwithData = JSON.parse(JSON.stringify(state.forthwith))
		let reserveData = JSON.parse(JSON.stringify(state.reserve))
		if (delivery_modeint == 1) {
			forthwithData.map(item => {
				item.checked = checked
			})
		} else {
			reserveData.map(item => {
				item.checked = checked
			})
		}
		commodityData.map(item => {
			if (item.base.delivery_modeint == delivery_modeint) {
				item.checked = checked
			}
		})
		commit('setCommodity', commodityData)
		commit('setForthwith', forthwithData)
		commit('setReserve', reserveData)
		return {
			commodityData,
			forthwithData,
			reserveData
		}
	},
	async cartAll({
		commit
	}) {
		let data = []
		if (getCookies('token')) {
			let cartAllData = await cartAll()
			data = cartAllData.data
		}
		let commodity = data || []
		let forthwith = []
		let reserve = []
		let invalid = []
		commodity.map(item => {
			item.checked = true
			item.CartId=item.cart.id
			item.Count=item.cart.count
			item.base=item.commodity 
			item.skuAc=item.sku 
			item.invalid=0
			//状态：1.待上架，2.上架 ，3.已过期，4.已删除
			let stock=0
			if (item.sku.stock_type == 0) {
				stock = parseInt(item.commodity.stock / item.sku.quantity)
			} else {
				stock = parseInt(item.commodity.stock / (item.sku.quantity * item.unit_info.proportion))
			}
			if(item.commodity.status!=2){
				item.invalid=1
				invalid.push(item)
			}else if(stock==0){
				item.invalid=2
				invalid.push(item)
			}else if (item.commodity.delivery_modeint == 1) {
				forthwith.push(item)
			} else {
				reserve.push(item)
			}
		})
		commit('setCommodity', commodity)
		commit('setForthwith', forthwith)
		commit('setReserve', reserve)
		commit('setInvalid', invalid)
		return {
			commodity,
			forthwith,
			reserve
		}
	},
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
