export default {
	'01':'Đăng nhập/ Đăng ký',
	'02':'Trang chủ',
	'03':'Định dạng điện thoại không chính xác',
	'04':'Nhập số điện thoại',
	'05':'Mã xác minh không được để trống',
	'06':'OTP',
	'07':'Mật khẩu không được để trống',
	'08':'Nhập mật khẩu',
	'09':'Lấy mã',
	'10':'OTP',
	'11':'Mật khẩu',
	'12':'Cài đặt mật khẩu',
	'13':'Mã mời',
	'14':'',
	'15':'Không bắt buộc điền',
	'16':'+63',
	'17':'Thoả thuận người dùng',
	'18':'Đặt lại mật khẩu',
	'19':'Thông tin chi tiết sản phẩm',
	'20':'Danh sách sản phẩm',
	'21':'Thông tin cá nhân',
	'22':'Mã giảm giá',
	'23':'Quản lý địa chỉ',
	'24':'Hỏi đáp rau củ',
	'25':'Trung tâm nạp tiền',
	'26':'Mời bạn bè',
	'27':'Về chúng tôi',
	'28':'Dịch vụ khách hàng',
	'29':'Giỏ hàng',
	'30':'Đặt hàng',
	'31':'Thanh toán',
	'32':'Kết quả đặt hàng',
	'33':'Đơn hàng của tôi',
	'34':'Thông tin chi tiết đặt hàng',
	'35':'ATải APP',
	'36':'Nhà cung cấp',
	'37':'Vị trí',
	'38':'Nạp tiền',
	'39':'Đại lý',
	'40':'Đăng xuất',
	'41':'Tiếng Trung', 
	'42':'Tiếng Anh',
	'43':'Tiếng Việt',
	'44':'Thêm địa chỉ mới',
	'45':'Sửa địa chỉ ',
	'46':'Người nhận',
	'47':'Số điện thoại',
	'48':'Thành phố',
	'49':'Mời lựa chọn',
	'50':'Thông tin địa chỉ chi tiết',
	'51':'Nhà',
	'52':'Công ty',
	'53':'Khác',
	'54':'Đặt làm địa chỉ mặc địch',
	'55':'Huỷ bỏ',
	'56':'Xác nhận',
	'57':'Lựa chọn địa chỉ ',
	'58':'Tuyệt vời~ Không cài đặt được địa chỉ nhận hàng',
	'59':'Tên không được để trống',
	'60':'Thành phố nhận hàng không được để trống ',
	'61':'Địa chỉ chi tiết ( ít nhất 5 kí tự )',
	'62':'Định dạng sai số điện thoại ', 
	'63':'Chỉnh sửa thành công',
	'64':'Thêm mới thành công',
	'65':'Người nhận hàng',
	'66':'Địa chỉ nhận hàng',
	'67':'Chỉnh sửa ',
	'68':'Chính sách',
	'69':'Hoa quả tươi',
	'70':'Tìm kiếm',
	'71':'Lịch sử tìm kiếm',
	'72':'Giỏ hàng của tôi',
	'73':'Thông báo',
	'74':'Đăng xuất',
	'75':'Lựa chọn quy cách sản phẩm',
	'76':'Giá cả',
	'77':'Quy cách',
	'78':'Số lượng',
	'79':'Thêm vào giỏ hàng',
	'80':'Đã đạt đến giới hạn số lượng',
	'81':'Thiếu hàng tồn kho',
	'82':'Phân loại',
	'83':'Nổi bật',
	'84':'Giá đặc biệt',
	'85':'Đặt trước',
	'86':'Nạp tiền tặng quà', 
	'87':'Đại lý',
	'88':'Không có giảm giá ngưỡng',
	'89':'Đầy',
	'90':'Giảm',
	'91':'Đã sử dụng',
	'92':'Hết hạn',
	'93':'Sắp hết hạn',
	'94':'Hết hạn',
	'95':'Ngày mai hết hạn',
	'96':'Kiểm tra thông tin chi tiết',
	'97':'Thu lại',
	'98':'Lựa chọn thành phố',
	'99':'Thời gian giao hàng',
	'100':'Giao hàng cấp tốc',
	'101':'Gói hàng đặt trước',
	'102':'Giao hàng hoả tốc',
	'103':'Cùng thời gian với gói hàng 1',
	'104':'Giao hàng nhanh không chọn lựa thời gian',
	'105':'Không có thời gian chọn cho sản phẩm đặt trước',
	'106':'Hôm nay',
	'107':'Ngày mai',
	'108':'Ngày kia',
	'109':'Có thể sử dụng mã giảm giá',
	'110':'Mã giảm giá chỉ hỗ trợ thanh toán trực tuyến',
	'111':'Wooho~ Không có mã giảm giá tương ứng',
	'112':'Đã hiểu',
	'113':'Không có địa chỉ nhận hàng được chọn',
	'114':'Tóm tắt đơn đặt hàng',
	'115':'Tổng cộng',
	'116':'Gửi đơn hàng', 
	'117':'Bạn có chắc xoá sản phẩm này?',
	'118':'Sản phẩm thường bán',
	'119':'Woohoo! Không có sản phẩm',
	'120':'Số mã giảm giá có thể dùng', 
	'121':'Kiểm tra',
	'122':'Mời lựa chọn địa chỉ', 
	'123':'Không có thông tin địa chỉ được chọn',
	'124':'Chuyển đổi',
	'125':'Chọn tất cả',
	'126':'Tên sản phẩm',
	'127':'Đơn giá',
	'128':'Tạm tính',
	'129':'Thao tác',
	'130':'Còn nữa',
	'131':'Mục hàng hoá bị thu gọn',
	'132':'Sản phẩm không hợp lệ',
	'133':'Xoá hàng loạt',
	'134':'Giỏ hàng trống~',
	'135':'Tất cả kết quả',
	'136':'Lượng tiêu thụ',
	'137':'Bộ lọc nhãn',
	'138':'Cài đặt lại',
	'139':'Kết quả tìm kiếm',
	'140':'Woohoo~ Không có sản phẩm mà bạn cần',
	'141':'Đề xuất hôm nay',
	'142':'Đặt trước',
	'143':'Thông báo nhận được hàng',
	'144':'hi sản phẩm được giao thành công, chúng tôi sẽ thông báo cho bạn trong thời gian sớm nhất',
	'145':'Thêm sản phẩm vào giỏ thành công',
	'146':'Chính sách đổi trả sau mua hàng',
	'147':'Tươi mới tự nhiên',
	'148':'Đảm bảo chất lượng',
	'149':'Giao hàng hoả tốc',
	'150':'Mọi người đều yêu thích',
	'151':'Xem thêm',
	'152':'Giá đặc biệt gần đây',
	'153':'Giao hàng hôm sau',
	'154':'Đăng nhập',
	'155':'Đăng kí',
	'156':'Đăng nhập nhanh',
	'157':'Đăng nhập mật khẩu',
	'158':'Quên mật khẩu',
	'159':'Đăng kí',
	'160':'Đăng nhập',
	'161':'Đăng kí',
	'162':'Thoả thuận người dùng của Yeah Fresh',
	'163':'Bằng cách đăng kí, bạn đồng ý với',
	'164':'Giảm giá đơn hàng đầu tiên cho người dùng',
	'165':'Tự động khấu trừ khi đặt hang, chỉ thanh toán trực tuyến',
	'166':'Chuyển đổi địa chỉ',
	'167':'Phí vận chuyển',
	'168':'Số tiền khuyến mãi',
	'169':'Tổng cộng',
	'170':'Đến bước thanh toán',
	'171':'Mã giảm giá',
	'172':'Nhập mã giảm giá',
	'173':'Hối đoái',
	'174':'Chọn lại',
	'175':'Lựa chọn mã giảm giá',
	'176':'Lựa chọn thời gian',
	'177':'Ghi chú',
	'178':'Điền thông tin cần ghi chú',
	'179':'Thông tin thanh toán',
	'180':'Mã số đặt hàng',
	'181':'hương thức thanh toán', 
	'182':'Miễn phí giao hàng',
	'183':'Thanh toán lại từ đầu',
	'184':'Đăng kí dịch vụ hậu mãi', 
	'185':'Vui lòng tải xuống thiết bị đầu cuối để đăng kí dịch vụ hậu mãi',
	'186':'Chờ thành toán',
	'187':'Chờ gửi hàng',
	'188':'Chờ nhận hàng',
	'189':'Đã hoàn thành',
	'190':'Đang tiến hành hậu mãi',
	'191':'Huỷ đơn đặt hàng',
	'192':'Hoàn thành dịch vụ hậu mãi',
	'193':'Chỉnh sửa ghi chú',
	'194':'Thông tin nhận hàng', 
	'195':'Huỷ đơn hàng',
	'196':'Địa chỉ nhận hàng',
	'197':'Thay đổi địa chỉ',
	'198':'Chỉnh sửa ghi chú',
	'199':'Thay đổi thời gian',
	'200':'Alipay',
	'201':'WeChatpay',
	'202':'Thanh toán khi nhận hàng',
	'203':'Số dư thanh toán',
	'204':'Thanh toán thành công',
	'205':'Đặt hàng thất bại',
	'206':'Yeah Fresh sẽ tiến hành giao hàng sớm nhất theo thời gian đã thoả thuận',
	'207':'Đơn đặt hàng sẽ tự động đóng sau 15 phút', 
	'208':'Đến trang mua sắm',
	'209':'Không có thời gian giao hàng được chọn',
	'210':'Chọn phương thức thanh toán',
	'211':'Thanh toán thực ', 
	'212':'Số dư Yeah Fresh',
	'213':'Thanh toán ngay',
	'214':'Thanh toán thành công chưa?',
	'215':'Hoàn thành',
	'216':'Chưa hoàn thàn',
	'217':'Số dư không đủ',
	'218':'Mật khẩu mới',
	'219':'Nhập mật khẩu mới',
	'220':'Mật khẩu bắt buộc',
	'221':'Nhập lại',
	'222':'Hai lần nhập lại mật khẩu không khớp',
	'223':'Chỉnh sửa thành công',
	'224':'Xác minh điện thoại',
	'225':'Bước tiếp theo',
	'226':'Số điện thoại xác minh',
	'227':'Sửa đổi hoàn thành',
	'228':'Còn chưa điền địa chỉ nhé',
	'229':'Thêm địa chỉ',
	'230':'Số dư',
	'231':'Thông báo',
	'232':'Có thể sử dụng',
	'233':'Thay đồi số điện thoại di động',
	'234':'Liên kết số điện thoại di động',
	'235':'Thay đổi thành công',
	'236':'Thay đổi ảnh đại diện',
	'237':'Ảnh đại diện',
	'238':'Thay đổi',
	'239':'Biệt danh',
	'240':'Sinh nhật',
	'241':'Email',
	'242':'Chọn ngày tháng',
	'243':'Giới tính',
	'244':'Số điện thoại',
	'245':'Lưu',
	'246':'Chưa thiết lập',
	'247':'Nam',
	'248':'Nữ',
	'249':'Bảo mật',
	'250':'Mời bạn bè để được hoàn tiền 5%',
	'251':'Số dư ví tiền của bạn',  
	'252':'Mời bạn bè để được hoàn lại 5% tiền mặt. Bạn mời càng nhiều bạn bè, thu nhập của bạn càng nhiều. Thu nhập có thể dùng để khấu trừ tiền đơn đặt hàng',
	'253':'Trở thành đại lý',
	'254':'Tổng doanh thu',
	'255':'Hôm qua',
	'256':'Tháng này',
	'257':'1. Làm thế nào để trở thành nhân viên quảng cáo Rau Xanh?', 
	'258':'1. Đăng nhập vào ứng dụng/ APP Rau Xanh, ai cũng có thể trở thành nhân viên quảng cáo Yeah Fresh.', 
	'259':'2. Nhân viên quảng cáo có thể mời bạn bè tham gia ứng dụng/APP Rau Xanh, chỉ cần bạn bè của bạn mua hàng trên Yeah Fresh, nhân viên quảng cáo có thể kiếm thêm thu nhập cả đời;',
	'260':'2. Làm thế nào để mời bạn bè?', 
	'261':'1. Ứng dụng WeChat：',
	'262':'① Bấm vào mời bạn bè → Lưu mã QR lời mời →Gửi cho bạn bè quét mã để vào Mini Programs → Hoàn thành đăng kí；',
	'263':'② Bấm vào mời bạn bè → Sao chép mã mời →  Gửi cho bạn bè → Vào Mini Programs điền mã mời -> Hoàn thành đăng kí;',
	'264':'Bấm vào mời bạn bè -> Sao chép mã mời -> Gửi cho bạn bè -> Vào APP điền mã mời -> Hoàn thành đăng kí;',
	'265':'3. Bấm vào "Khách hàng của tôi", có thể kiểm tra bạn bè mời thành công.',
	'266':'3. Các cấp độ và lợi ích của người quảng cáo Yeah Fresh?',
	'267':'1. Người quảng cáo có thể nhận được tiền thưởng 0,5%  trên số tiền tiêu dùng của khách hàng, số dư sẽ được tự động gửi vào tài khoản;', 
	'268':'2. Khi đơn hàng của khách hoàn tất, phần thưởng sẽ tự động tất toán gửi vào tài khoản, chi tiết xem tại mục "Doanh thu”;',
	'269':'Mời bạn bè để nhận',
	'270':'Thời gian thực',
	'271':'Giao dịch hoàn thành, tín dụng tức thì', 
	'272':'Tải xuống mục chia sẻ phần thưởng',
	'273':'Thu nhập của tôi',
	'274':'Mời bạn bè để nhận được phần thưởng tiền mặt',
	'275':'Khách hành của tôi',
	'276':'Biệt danh bạn bè ',
	'277':'Thời gian phát hành',
	'278':'Woohoo~ Tạm thời chưa có bạn bè',
	'279':'Số tiền mặt hoàn lại',
	'280':'Woohoo~Tạm thời không có doanh thu', 
	'281':'Trung tâm tin nhắn',
	'282':'Tin nhắn đặt hàng',
	'283':'Thông tin khuyến mãi',
	'284':'Woohoo~ Tạm thời không có tin nhắn mới',
	'285':'Thời gian đặt hàng', 
	'286':'Số đơn đặt hàng',
	'287':'Tự động đóng đơn đặt hàng', 
	'288':'Tổng cộng',
	'289':'Kiện hàng hoá',
	'290':'Đến bước thanh toán',
	'291':'Phút',
	'292':'Giây',
	'293':'Tất cả đơn hàng',
	'294':'Tạm thời không có đơn đặt hàng',
	'295':'Bắt đầu mua sắm',
	'296':'Lịch sử chi tiết',
	'297':'Thời gian giao dịch',
	'298':'Mã số thanh toán',
	'299':'Hoàn tiền',
	'300':'Gặp vấn đề khi đặt hàng?',
	'301':'Liên hệ dịch vụ khách hàng',
	'302':'Thông tin nạp tiền',
	'303':'Số dư ví tiền',
	'304':'Lựa chọn số dư',
	'305':'Giới thiệu',
	'306':'Tuỳ chỉnh',
	'307':'Vui lòng nhập số tiền bạn muốn nạp',
	'308':'Bạn muốn thanh toán thế nào?',
	'309':'Lịch sử nạp tiền',
	'310':'Gợi ý',
	'311':'Nếu bạn có bất cứ vấn đề nào trong quá trình liên kết, vui lòng liên hệ dịch vụ khách hàng trực tuyến.',
	'312':'Thay đổi liên kết số điện thoại. Nếu số điện thoại ban đầu đã bị vô hiệu hoá không lấy được mã xác minh vui lòng liên hệ dịch vụ khách hàng trực tuyến.',
	'313':'Tối thiểu 1P',
	'314':'Nạp tiền đã hoàn tất chưa?',
	'315':'Lịch sử mua sắm',
	'316':'Thời gian',
	'317':'Phương thức nhập sổ',
	'318':'Trạng thái',
	'319':'Số tiền',
	'320':'Chi phí đơn đặt hàng',
	'321':'Rút',
	'322':'Tiền hoa hồng',
	'323':'Đổi phiếu giảm giá',
	'324':'Phí phục vụ',
	'325':'Thành công',
	'326':'Đơn hàng đã được huỷ',
	'327':'Woohoo~ Tạm thời không có đơn hàng',
	'328':'Quét mãu QR để tải xuống APP Yeah Fresh',
	'329':'Hoặc vào App Store để tìm kiếm',
	'330':'Quét mã QR trên WeChat để sử dụng Mini Programs của Yeah Fresh',
	'331':'Hoặc truy cập WeChat A tìm kiếm', 
	'332':'Yeah Fresh hoan nghênh các đối tác cung cấp sản phẩm trung thực, chuẩn mực, chất lượng cao cùng tham gia!',
	'333':'Có thể cung cấp các điều kiện thuận lợi mở cửa hành kinh doanh như mặt bằng, kho lạnh, nền tảng bán hàng, nhân viên giao hàng, thu tiền đơn hàng, Grab, Foodpanda, Shopee, Lazada!', 
	'334':'Nội dung hợp tác  Nội dung hợp tác ',
	'335':'Tươi sống/ Trái cây/ Thịt/ Hải sản/ Thực phẩm đông lạnh/ Đồ ăn vặt/ Bánh kem/ Món kho/ Salad/ Thức ăn nhẹ/ Đồ uống/ Hoa tươi/ Mẹ và bé/ Chăm sóc sắc đẹp/ Vệ sinh hằng ngày/ Sản phẩm điện tử 3C/ Khác',
	'336':'Tiến trình',
	'337':'Yeah Fresh được thành lập vào năm 2021, công ty sở hữu nhiều vị trí hàng đầu và cửa hàng tự vận hành, đồng thời có website và APP tự phát triển độc lập, Rau Xanh đặt mục tiêu trở thành nền tảng thương mại điện tử trực tiếp lớn nhất Philippines. Chúng tôi chân thành chào đón tất cả nhà cung cấp, Rau Xanh hoan nghênh các đối tác cung cấp sản phẩm trung thực, chuẩn mực, chất lượng cao cùng tham gia!  Chúng tôi có thể cung cấp các điều kiện thuận lợi mở cửa hành kinh doanh như mặt bằng, kho lạnh, nền tảng bán hàng, nhân viên giao hàng, thu tiền đơn hàng, Grab, Foodpanda, Shopee, Lazada!',
	'338':'Nền tảng đối tác của chúng tôi',
	'339':'số dư thanh toán hậu cần. ',
	'340':'thanh toán số dư tại cửa hàng. ',
	'341':'thanh toán số dư tại cửa hàng bằng tiền mặt.',
	'342':'chuyển phát thường thanh toán khi giao hàng',
	
	
	
	"400": "Lỗi tham số yêu cầu",
	"401": "Session timeout",
	"403": "Không truy cập được",
	"500": "Lỗi máy chủ", //系统性错误，用于调试，一般为第三方错误，数据库错误，表单验证错误，RPC服务器超时或中断等错误，前端根据情况处理。
	//用户错误
	"4001": "Tài khoản người dùng không tồn tại",
	"4002": "Tài khoản người dùng bị khoá",
	"4003": "Lịch sử cài đặt người dùng không tồn tại",
	"4004": "Trạng thái người dùng chưa xác minh hoặc đã kiểm duyệt hoặc đã bị khoá",
	"4005": "Người dùng không tồn tại",
	"4006": "Người dùng đã tồn tại",
	"4007": "Tài khoản người dùng không hợp lệ",
	"4008": "Token người dùng trống", 
	"4009": "Người dùng tạm thời không thể đăng nhập",
	"4010": "Mục nhập trùng lặp",
	"4011": "Mật khẩu người dùng sai",
	"4012": "Mã xác minh trống",
	"4013": "Người bán chưa gửi thông tin xác thực",
	"4014": "Token người dùng không hợp lệ",
	"4015": "Thông tin cài đặt người dùng đã tồn tại",
	"4016": "Mã xác minh hết hạn", 
	"4017": "Lỗi gửi mail",
	"4018": "Mã xác minh không hợp lệ",
	"4019": "Token người dùng hết hạn",
	"4020": "Mã mời không tồn tại",
	"4021": "Đăng nhập không thành công",
	"4022": "Token vô hiệu hoá",
	"4023": "Lỗi mã xác minh",
	"4024": "Người dùng không tồn tại hoặc sai mật khẩu",
	"4025": "Tài khoản đã bị xoá ",
	"4026": "Email đã được đăng kí ",
	"4027": "Tài khoản đã được đăng kí, mời đăng nhập",
	"4028": "Đăng ký không thành công",
	"4029": "Định dạng email không chính xác ",
	"4030": "Độ dài mật khẩu không được ít hơn 6 kí tự",
	"4031": "Biệt danh là trường bắt buộc",
	"4032": "Số điện thoại đã được đăng kí",
	"4033": "Định dạng số điện thoại sai",
	"4034": "Vui lòng không gửi tin nhắn văn bản thường xuyên",
	"4035": "Số điện thoại chưa được đăng kí, vui lòng đăng kí rồi tiến hành đăng nhập",
	"4036": "Liên kết không thành công",
	"4037": "Sửa đổi mật khẩu không thành công",
	"4038": "Độ dài tên người nhận hàng không thể dài hơn 20 kí tự", //英文改为20个字符
	"4039": "Vui lòng lựa chọn thành phố giao hàng",
	"4040": "Vui lòng điền đầy đủ chi tiết địa chỉ giao hàng",
	"4041": "Thành phố giao hàng phải được chọn",
	"4042": "Không thể thêm địa chỉ giao hàng",
	"4043": "Địa chỉ giao hàng chưa được đặt",
	"4044": "Sửa đổi số lượng giỏ hàng không thành công",
	"4045": "Thêm giỏ hàng không thành công",
	"4046": "Xoá giỏ hàng không thành công",
	"4047": "Thăng cấp đại lí không thành công",
	"4048": "Nạp tiền không thành công",
	"4049": "Chưa nhận được phí vận chuyển",
	"4050": "Thành phố chọn không nằm trong phạm vi giao hàng",
	"4051": "Mã giảm giá này không thể sử dụng cho đơn hàng này",
	"4052": "Số tiền tiêu dùng không thoả mãn điều kiện sử dụng mã giảm giá",
	"4053": "Đơn hàng này không nằm trong phạm vi giao hàng",
	"4054": "Giá không khớp, vui lòng gửi lại",
	"4055": "Số dư không đủ để thanh toán cho đơn hàng này",
	"4056": "Mã giảm giá này không tồn tại",
	"4057": "Vui lòng chọn thời gian giao hàng chính xác",
	"4058": "Đăng kí dịch vụ hậu mãi không thành công, vui lòng thử lại",
	"4059": "Vui lòng chọn chính xác phương thức thanh toán",
	"4060": "Số dư của người dùng không đủ để xem số tiền được khấu trừ",
	"4061": "Vui lòng chọn loại",
	"4062": "Mã giảm giá đặt hàng giao hàng nhanh không tồn tại ",
	"4063": "Mã giảm giá đặt hàng trước không tồn tại ",
	"4064": "ố tiền tiêu thụ đơn hàng giao hoả tốc không đáp ứng các điều kiện sử dụng mã giảm giá",
	"4065": "Số tiền tiêu thụ đơn hàng đặt trước không đáp ứng các điều kiện sử dụng mã giảm giá ",
	"4066": "Mã giảm giá này không thể sử dụng cho các đơn hàng giao hoả tốc",
	"4067": "Mã giảm giá này không thể sử dụng cho các đơn hàng đặt trước",
	
	
	//
	"5001": "Mã xác minh đã đạt đến giới hạn tối đa trong khoảng thời gian được yêu cầu",
	"5002": "Mã xác thực vẫn còn trong khoảng thời gian được yêu cầu",
	"5003": "Thêm không thành công ",
	"5004": "Cập nhật không thành công",
	"5005": "Xoá không thành công",
	"5006": "Không có quyền truy cập",
	"5007": "Không có quyền thao tác ",
	"5008": "Tham số sai",
	"5009": "Dữ liệu không tồn tại",
	"5010": "Tạo mã xác minh không thành công",
	"5011": "ID thực đơn không được để trống",
	"5012": "Role ID không được để trống",
	"5013": "Không có bất kì quyền truy cập nào",
	"5014": "Thao tác không thành công",
	"5015": "Chỉnh sửa trạng thái không thành công",
	"5016": "Sản phẩm không tồn tại hoặc ngừng kinh doanh",
	"5017": "Dữ liệu đã tồn tại",
	"5018": "Gửi mã xác minh không thành công",
	
	
	//order
	"6001": "Mã giao dịch không tồn tại",
	"6002": "Đơn đặt hàng hết hạn",
	"6003": "Nhãn bình luận đã tồn tại",
	"6004": "Mã hàng hoá SKU đã tồn tại trong hệ thống",
	"6005": "Số giao dịch đặt hàng trống",
	"6006": "Thời gian thanh toán hết hạn",
	"6007": "Đơn đặt hàng đang được thanh toán",
	"6008": "Trạng thái đơn hàng không hợp lệ",
	"6009": "Đơn hàng đã hoàn thành thanh toán",
	"6010": "Phiên bản giá sản phẩm không tồn tại",
	"6011": "Đơn hàng đã hoàn thành thanh toán",
	"6012": "Số giao dịch bên ngoài trống", 
	"6013": "Đơn hàng giao dịch không khớp với người dùng hiện tại",
	"6014": "Trạng thái đơn hàng đã bị khoá",
	"6015": "Mã hàng hoá SKU không tồn tại",
	"6017": "Không đủ hàng tồn kho",
	"6018": "Số dư người dùng không đủ",
	"6019": "Lỗi giải tích định dạng số tiền",
	"6020": "Đơn hàng đã tồn tại",
	"6021": "ID trống",
	"6022": "Đơn hàng của người dùng không tồn tại",
	"6023": "Lịch sử phân phối đã tồn tại",
	"6024": "Thực hiện quản trị không thành công",
	"6025": "Lịch sử phân phối không tồn tại",
	"6026": "Địa chỉ giao hàng phân phối của người dùng không tồn tại",
	"6027": "Nhãn bình luận không tồn tại",
	"6028": "Số giao dịch đơn đang được thanh toán",
	"6029": "Tạo đơn hàng không thành công",
	"6030": "Thanh toán số dư không thành công",
	"6031": "Huỷ bỏ đơn hàng không thành công",
	"6032": "Đặt hàng không thành công, vui lòng thử lại sau", 
	"6033": "Hết hàng, vui lòng thử lại sau Hết hàng",
	"6034": "Thay đổi đơn hàng không thể huỷ bỏ",
	"6035": "Đơn hàng này đã đăng kí dịch vụ hậu mãi, vui lòng kiên nhẫn đợi kết quả xử lý",
	"6036": "Đơn hàng này đã sử dụng mã giảm giá, không áp dụng thanh toán khi nhận hàng",
	"6037": "Đơn hàng đã vượt quá thời gian thanh toán",
	"6038": "ơn hàng đã huỷ, không thể thanh toán lại từ đầu ",
	"6039": "Vui lòng lựa chọn sản phẩm bạn muốn mua ",
	"6040": "Ở trường hợp này, phí vận chuyển không thể miễn giảm",
	"6041": "Đơn hàng này đã tìm được tài xế giao hàng",
	"6042": "Đơn hàng này đã thu tiền",
	"6043": "Số tiền thanh toán không chính xác", 
	"6044": "Sử dụng mã khuyến mãi không thành công",
	//7000
	"7000": "Tải tệp lên không thành công",
	"7001": "Tạo tệp mới không thành công",
	"7002": "Lưu tệp không thành công"
}