export default {
	'01':'Login/Sign up',
	'02':'Home',
	'03':'Phone number format is invalid.',
	'04':'Please put phone number',
	'05':'OTP is required',
	'06':'Enter OTP',
	'07':'Password is required',
	'08':'Enter Password',
	'09':'Receive OTP',
	'10':'OTP',
	'11':'Password',
	'12':'Password',
	'13':'OTP',
	'14':'',
	'15':'Optional',
	'16':'+63',
	'17':'User Agreement',
	'18':'Reset Password',
	'19':'Product Info',
	'20':'Product List',
	'21':'Personal Info',
	'22':'Vouchers',
	'23':'Manager Address',
	'24':'Q&A',
	'25':'Cash-in',
	'26':'Invite Friends',
	'27':'About Us',
	'28':'CUSTOMER SERVICE',
	'29':'Cart',
	'30':'Order',
	'31':'Pay',
	'32':'Result',
	'33':'My Orders',
	'34':'Order Info',
	'35':'Download App',
	'36':'Suppliers',
	'37':'Location',
	'38':'Cash-in',
	'39':'Affiliate',
	'40':'Logout',
	'41':'Chinese',
	'42':'English',
	'43':'Vietnamese',
	'44':'Create Address',
	'45':'Edit Address',
	'46':'Recipient',
	'47':'Phone Number',
	'48':'City',
	'49':'Please Select',
	'50':'Detailed Address',
	'51':'Home',
	'52':'Company',
	'53':'Others',
	'54':'Set as default address',
	'55':'Cancel',
	'56':'Confirm',
	'57':'Select Address',
	'58':'Opps~no address is set',
	'59':'Name is required',
	'60':'City is required',
	'61':'Min 5 characters for detailed address',
	'62':'Invalid format for phone number',
	'63':'Edited',
	'64':'Added',
	'65':'Recipient',
	'66':'Delivery Address',
	'67':'Edit',
	'68':'Policy',
	'69':'Fruits',
	'70':'Search',
	'71':'Search History',
	'72':'My Cart',
	'73':'Notice',
	'74':'Logout',
	'75':'Select Product Specs',
	'76':'Price',
	'77':'Specs',
	'78':'Quantity',
	'79':'Add to Cart',
	'80':'Reached Purchase Limit',
	'81':'Insufficient Stock',
	'82':'Categories',
	'83':'Selected',
	'84':'Promotions',
	'85':'Pre-orders',
	'86':'Cash-in Promo',
	'87':'Invite friends and earn commission',
	'88':'No Requirement',
	'89':'Min Spend',
	'90':'Less',
	'91':'Used',
	'92':'Invalid',
	'93':'Expiring',
	'94':'Expired',
	'95':'days to expire',
	'96':'View Details',
	'97':'Collapse',
	'98':'Select City',
	'99':'Delivery Time',
	'100':'Deliver Now',
	'101':'Pre-order',
	'102':'Deliver Now',
	'103':'Same as package 1',
	'104':'Delivery timeslot is not chosen for deliver now pacakge',
	'105':'Delivery timeslot is not chosen for pre-order pacakge',
	'106':'Today',
	'107':'Tomorrow',
	'108':'The day after tomorrow',
	'109':'Vouchers Available',
	'110':'Vouchers are available for online payment only',
	'111':'Opps~no vouchers are applicable',
	'112':'Noted',
	'113':'No delivery address selected',
	'114':'Order Info',
	'115':'Total',
	'116':'Submit',
	'117':'Delete Product?',
	'118':'Buy Again',
	'119':'Opps~no product',
	'120':'Available',
	'121':'View',
	'122':'Select Address',
	'123':'No address info selected',
	'124':'Switch',
	'125':'Select All',
	'126':'Product Name',
	'127':'Price',
	'128':'Sub-total',
	'129':'Edit',
	'130':'And',
	'131':'products are collapsed',
	'132':'Invalid Products',
	'133':'Delete More',
	'134':'Your cart is empty~',
	'135':'All Results',
	'136':'Sales',
	'137':'Label Filter',
	'138':'Reset',
	'139':'Search Results',
	'140':'Opps~ No results',
	'141':'Recommended',
	'142':'Pre-order',
	'143':'Restock Notice',
	'144':'Inform me when the product is restocked',
	'145':'Successfully Purchase',
	'146':'Excellent Customer Service',
	'147':'Fresh and Natural',
	'148':'Guaranteed Quality',
	'149':'Express Delivery',
	'150':'We all like',
	'151':'View More',
	'152':'Discounted Products',
	'153':'Next-day Delivery',
	'154':'Login',
	'155':'Sign-up',
	'156':'Express Login',
	'157':'Password Login',
	'158':'Forget Password',
	'159':'Sing-up',
	'160':'Login',
	'161':'Sign-up',
	'162':'Yeah Fresh User Agreement',
	'163':'By signing up, you agree to',
	'164':'New users enjoy discount of',
	'165':'Deducted upon checkout, online payment only',
	'166':'Change Address',
	'167':'Delivery Fee',
	'168':'Discounted Amount',
	'169':'Total',
	'170':'Checkout',
	'171':'Voucher Code',
	'172':'Enter Voucher Code',
	'173':'Reimburse',
	'174':'Select Again',
	'175':'Select Voucher',
	'176':'Select Time',
	'177':'Remarks',
	'178':'Select Your Remarks',
	'179':'Payment Info',
	'180':'Order Number',
	'181':'Payment Method',
	'182':'Free Delivery',
	'183':'Pay Again',
	'184':'Service Support',
	'185':'Please download app for Service Support',
	'186':'To Pay',
	'187':'To Deliver',
	'188':'To Receive',
	'189':'Completed',
	'190':'Service Support in Progress',
	'191':'Order Cancelled',
	'192':'Service Support Completed',
	'193':'Edit Remarks',
	'194':'Delivery Info',
	'195':'Cancel Order',
	'196':'Delivery Address',
	'197':'Change Address',
	'198':'Edit Remarks',
	'199':'Edit Time',
	'200':'Alipay',
	'201':'WeChatpay',
	'202':'COD',
	'203':'Balance',
	'204':'Paid',
	'205':'Failed',
	'206':'Yeah Fresh will deliver according to the timeslot chosen',
	'207':'The order will be cancelled in 15mins',
	'208':'To Shop',
	'209':'Delivery Time Is Not Chosen',
	'210':'Select Payment Method',
	'211':'Amount to Pay',
	'212':'Balance',
	'213':'Rename',
	'214':'Is the payment completed?',
	'215':'Completed',
	'216':'Not Completed',
	'217':'Insufficient Balance ',
	'218':'New Password',
	'219':'Re-enter New Password',
	'220':'Password Is Required',
	'221':'Re-enter',
	'222':'The two passwords are different',
	'223':'Edited',
	'224':'Verify Your Phone Number',
	'225':'Next',
	'226':'Verify Your Phone Number',
	'227':'Edited',
	'228':'No Address Found',
	'229':'Add Address',
	'230':'Balance',
	'231':'Notifications',
	'232':'Available',
	'233':'Edit Phone Number ',
	'234':'Verify Phone Number',
	'235':'Edited',
	'236':'Change Profile Pic',
	'237':'Profile Pic',
	'238':'Edit',
	'239':'Nickname',
	'240':'Date of Birth',
	'241':'Email Address',
	'242':'Select Date',
	'243':'Gender',
	'244':'Phone Number',
	'245':'Save 存',
	'246':'Not Set',
	'247':'Male',
	'248':'Female',
	'249':'Prefer Not To Tell',
	'250':'Invite your friends and enjoy 5% cashback ',
	'251':'Your Balance',
	'252':'Invite friends to enjoy 5% cashback. The more you invite, the higher your cashback is. Your cashback can be used for your orders.',
	'253':'Become Affiliate',
	'254':'Total Income',
	'255':'Yesterday',
	'256':'This month',
	'257':'1. How to become Yeah Fresh Affiliate?',
	'258':'1. Login Yeah Fresh App or WeChat Mini Program, everyone can be Yeah Fresh affiliate.',
	'259':'2. Affilates can invite friends to sign-up on Yeah Fresh App or Mini Program. As long as your friends order on Yeah Fresh, you can receive lifelong commissions;',
	'260':'2. How to Invite Friends?',
	'261':'1. WeChat Mini Program:',
	'262':'① Click Invite Friends → Save the QR Code →. Send it to your friends for entering Mini Program → Sign-up;',
	'263':'② Click Invite Friends → Copy Invitation Code → Send it to your friends → Enter Mini Program to fill in invitation code → Sign-up;',
	'264':'Click Invite Friends → Copy Invitation Code → Send it to your friends → Enter App to fill in invitation code → Sign-up;',
	'265':'3. Click "My Customers” and view your affiliated friends.',
	'266':'3. What are the ranks and commission rates of Yeah Fresh affiliates? ',
	'267':'1. Affiliates can enjoy 05% of affiliated customers spending, automatically credited to account balance;',
	'268':'2. When customers ordered, commissions will be credited to your balance automatically, you can view them in "Income”;',
	'269':'Invite friends to earn',
	'270':'Real-time',
	'271':'Credited immediately upon orders completed',
	'272':'Download and enjoy',
	'273':'My Income',
	'274':'Invite friends to get cashback',
	'275':'My Customers',
	'276':'Friends Nicknames',
	'277':'Release Time',
	'278':'Opps~ no friends invited as of now',
	'279':'Cashback Amount',
	'280':'Opps~ no income as of now',
	'281':'Notifications Center',
	'282':'Order Notifications',
	'283':'Promotions Notifications',
	'284':'Opps~ no notifcations',
	'285':'Order Time',
	'286':'Order Number',
	'287':'Order Closed Automatically',
	'288':'Total of',
	'289':'pieces of products',
	'290':'To Pay',
	'291':'Minutes',
	'292':'Seconds',
	'293':'All Orders',
	'294':'No order as of now',
	'295':'To Shop',
	'296':'Detailed Records',
	'297':'Transaction Time',
	'298':'Transaction Number ',
	'299':'Refund',	
	'300':'Issues with your order?',	
	'301':'Contact Service Support',	
	'302':'Cash-in Info',	
	'303':'Balance',	
	'304':'Select Balance',	
	'305':'Give',	
	'306':'Customize',	
	'307':'Select Cash-in Amount',	
	'308':'How would you pay?',	
	'309':'Cash-in History',	
	'310':'Reminder',	
	'311':'If you have any issue during binding process, please contact customer service.',	
	'312':'Change phone number. If your original number is banned and is not able to receive ORP, please contact customer service.',
	'313':'Min P1',
	'314':'Is the cash-in completed?',
	'315':'Order History',
	'316':'Time',
	'317':'Payment Method',
	'318':'Status ',
	'319':'Amount ',
	'320':'Order Amount',
	'321':'Withdraw',
	'322':'Commission',
	'323':'Vouchers for',
	'324':'Service Charge',
	'325':'Successful ',
	'326':'The order is cancelled ',
	'327':'Opps~ no order as of now',
	'328':'Scan the QR code to download Yeah Fresh App',
	'329':'or search Yeah Fresh in App Store or Google Play',
	'330':'Scan QR code with WeChat to use Yeah Fresh Mini Program',
	'331':'or search Yeah Fresh on WeChat',
	'332':'Yeah Fresh is looking for legitimate and high-quality products suppliers and partners!',
	'333':'We can provide physical locations, cold storage, distribution and sales channels, logistics and last-mile delivery, cash handling, and management of 3-rd party platforms such as Grab, Foodpanda, Shopee and Lazada!',
	'334':'Categories',
	'335':'Fruits, vegetables, meat, frozen food, snacks, bakery, stewed dishes, salads, beverages, flowers, infant products, skincare, cosmetics, household cleaning, consumer electronics and others',
	'336':'Yeah Fresh',
	'337':'Yeah Fresh was established in 2021. Currently we have a number of self-operated warehouses and stores, and our independently developed mobile app and website. Yeah Fresh aims to be the largest direct-operated e-commerce platform in the Philippines. Now we are looking for legitimate and high-quality products suppliers and partners, and we are open to wide range of business partnerships!',
	'338':'Our Partners',
	'339':'Pay with Balance',
	'340':'Pay with Balance (Store Pick-up)',
	'341':'Cash-on-Pickup',
	'342':'Cash-on-Delivery',
	
	
	
	"400": "Parameters Error",
	"401": "Conversation Timeout",
	"403": "No Authority to Visit",
	"500": "Server Error", //系统性错误，用于调试，一般为第三方错误，数据库错误，表单验证错误，RPC服务器超时或中断等错误，前端根据情况处理。
	
	//用户错误
	"4001": "The account does not exist",
	"4002": "The account is locked",
	"4003": "The account setting record does not exist",
	"4004": "The account is not verified",
	"4005": "The account does not exist",
	"4006": "The account exists",
	"4007": "The user account is invalid",
	"4008": "The account token is empty",
	"4009": "The account is restricted to login",
	"4010": "Duplicate entry",
	"4011": "The account password is incorrect",
	"4012": "The OTP is empty",
	"4013": "The merchant has not provided verification documents",
	"4014": "The account token in invalid",
	"4015": "The user settings info exists",
	"4016": "The OTP is expired",
	"4017": "Fail to send Email",
	"4018": "The OTP is invalid",
	"4019": "The user token is expired",
	"4020": "The Invitation Code does not exist",
	"4021": "Login Failed",
	"4022": "Invald Token",
	"4023": "Incorrect OTP",
	"4024": "The user does not exist or wrong password",
	"4025": "The account is deleted",
	"4026": "The email address is registered ",
	"4027": "The account is registered, please login",
	"4028": "Sign-up Failed",
	"4029": "Email address format is incorrect",
	"4030": "Min 6 digits for the password",
	"4031": "Nickname is required",
	"4032": "The number is registered",
	"4033": "Phone number format is incorrect",
	"4034": "Please don't send OTP frequently",
	"4035": "The number is not registered, please sign-up first",
	"4036": "Binding Failed ",
	"4037": "Fail to edit password",
	"4038": "Delivery address cannot be longer than 20 characters", //英文改为20个字符
	"4039": "Select City",
	"4040": "Please enter complete address",
	"4041": "City is required",
	"4042": "Fail to add delivery address",
	"4043": "No delivery address available",
	"4044": "Fail to edit cart",
	"4045": "Fail to add to cart",
	"4046": "Fail to delete in the cart",
	"4047": "Fail to upgrade affiliate",
	"4048": "Fail to cash-in",
	"4049": "Fail to compute delivery fee ",
	"4050": "The city is not in our delivery area",
	"4051": "The voucher is not applicable to this order",
	"4052": "The order amount does not meet the voucher's requirements",
	"4053": "The order is not in our delivery area",
	"4054": "The prices are not the same, please re-submit",
	"4055": "The balance is insufficient to pay",
	"4056": "The voucher does not exist",
	"4057": "Please select correct delivery time",
	"4058": "Fail to apply service support, please try again",
	"4059": "Please select correct payment method",
	"4060": "The balance is insufficient for this order amount",
	"4061": "Please select type",
	"4062": "The voucher does not exist for deliver-now order",
	"4063": "The voucher does not exist for pre-order",
	"4064": "The amount of deliver-now order does not meet the min requirement of this voucher",
	"4065": "The amount of pre-order does not meet the min requirement of this voucher",
	"4066": "The voucher is not applicable for deliver-now orders",
	"4067": "The voucher is not applicable for pre-orders",
	
	
	//
	"5001": "Too many requests of OTP",
	"5002": "Requests of OTP are still within allowed range",
	"5003": "Fail to add",
	"5004": "Fail to edit",
	"5005": "Fail to delete",
	"5006": "Not authorized to visit",
	"5007": "Not authorized to edit",
	"5008": "Parameter Error",
	"5009": "Data does not exist",
	"5010": "Fail to generate OTP",
	"5011": "Menu ID cannot be empty",
	"5012": "Role ID cannot be empty",
	"5013": "Not authorised to visit",
	"5014": "Fail to edit",
	"5015": "Fail to edit status",
	"5016": "The product does not exist or it is removed",
	"5017": "Data exists",
	"5018": "Fail to send OTP",
	
	
	//order
	"6001": "The transaction number does not exist",
	"6002": "The order expired",
	"6003": "The comment label exists",
	"6004": "Produce SKU-code exist in the system",
	"6005": "Order number is empty",
	"6006": "Transaction timeout",
	"6007": "The order is being paid now",
	"6008": "The order status is invalid",
	"6009": "The order is paid",
	"6010": "The product price version does not exist",
	"6011": "The order is paid",
	"6012": "The external transaction number is empty",
	"6013": "The order does not match this user",
	"6014": "Order status is locked",
	"6015": "Product SKU-code does not exist",
	"6017": "The product inventory is in sufficient",
	"6018": "The user's balance is insufficient",
	"6019": "Error in the format of amount",
	"6020": "The order existed",
	"6021": "ID is empty",
	"6022": "The user's order does not exist",
	"6023": "The logistic record existe",
	"6024": "Fail to operate",
	"6025": "Logistic record doesn't not exist",
	"6026": "The user's logistic record does not exist",
	"6027": "Comment label does not exist",
	"6028": "The order of this transaction number is being paid",
	"6029": "Fail to create order",
	"6030": "Fail to pay with balance",
	"6031": "Fail to cancel order",
	"6032": "Fail to order, please try again later",
	"6033": "Insufficient stock, please try again later",
	"6034": "The order cannot be cancelled",
	"6035": "The order is in service support, please wait for the feedback patiently",
	"6036": "Voucher is applied for this order, COD is not supported",
	"6037": "The order's transaction timeout",
	"6038": "The order is cancelled, you cannot pay again",
	"6039": "Please select the products you want to purchase",
	"6040": "The status is not applicable for delivery fee discount",
	"6041": "The order is assigned to rider",
	"6042": "The order payment is received",
	"6043": "The payment received is incorrect",
	"6044": "The voucher code is invalid",
	//7000
	"7000": "Fail to upload the file",
	"7001": "Fail to create file",
	"7002": "Fail to save file"
}