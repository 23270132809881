<template>
	<div id="app" :style="'font-family:'+fontFamily+';'">
		<headTop></headTop>
		<div class="appMin">
			<!-- <keep-alive>
				<router-view v-if="$route.meta.keepAlive"></router-view>
			</keep-alive>
			<router-view v-if="!$route.meta.keepAlive"></router-view> -->
			<router-view></router-view>
		</div>
		<!-- <foot></foot> -->
		<addCartPopup></addCartPopup>
	</div>
</template>

<script>
	import {
		setCookies,
		getCookies
	} from "@/utils/cookies.js"
	import {
		commodityLabel
	} from "@/api/commodity.js"
	import headTop from "@/components/topBot/headTop.vue"
	// import foot from "@/components/topBot/footer.vue"
	import addCartPopup from "@/components/addCartPopup.vue"
	export default {
		name: 'App',
		components: {
			headTop,
			// foot
			addCartPopup
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			}
		},
		watch: {
			lang() {
				if (this.$route.meta.title) { //如果设置标题，拦截后设置标题
					document.title = this.i18n(this.$route.meta.title)
				}
			}
		},
		data() {
			return {
				fontFamily: 'Microsoft YaHei',
			}
		},
		async mounted() {
			var agent = navigator.userAgent.toLowerCase();
			var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
			if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
				this.fontFamily = 'Microsoft YaHei'
			}
			if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
				this.fontFamily = 'Microsoft YaHei'
			}
			if (isMac) {
				this.fontFamily = 'PingFangSC-Regular, PingFang SC'
			}
			if (getCookies('token')) {
				//更新用户信息
				this.$store.dispatch('user/getUserInfo')
				//更新购物车信息
				this.$store.dispatch('cart/cartAll')
			} else {
				console.log('没有token')
			}
			let {
				data
			} = await commodityLabel()
			setCookies('commodityLabel', JSON.stringify(data))
			this.$store.dispatch('serviceArea/storehouseCityList')
		},
	}
</script>

<style>
	#app {
		font-family: PingFangSC-Regular, PingFang SC;
		color: #2c3e50;
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	body {
		scroll-behavior: smooth;
	}

	a {
		text-decoration: none
	}

	* {
		margin: 0;
		padding: 0;
	}

	input {
		border: none;
		background: none;
	}

	input::-moz-placeholder,
	input::-ms-input-placeholder,
	input::-webkit-input-placeholder {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	input::-webkit-input-placeholder {
		/* WebKit browsers */
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	input:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	input::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	input:-ms-input-placeholder {
		/* Internet Explorer 10+ */
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		font-weight: 400;
	}

	input:focus-visible {
		outline: none;
	}

	img {
		display: block;
	}

	.width1400 {
		width: 1400px;
		margin: 0 auto;
	}

	.over {
		overflow: hidden;
		word-break: keep-all;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.over2 {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		/*! autoprefixer: off */
		-webkit-box-orient: vertical;
		/*! autoprefixer: on */
		white-space: initial;
		overflow: hidden;
	}

	input[type=number] {
		-moz-appearance: textfield;
	}

	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	:focus-visible {
		outline: none;
	}

	.el-select .el-input.is-focus .el-input__inner,
	.el-input.is-active .el-input__inner,
	.el-input__inner:focus,
	.el-textarea__inner:focus {
		/* border: 1px solid #007E3D !important; */
		border: 1px solid #DCDFE6 !important;
	}

	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background: #007E3D !important;
	}

	.el-pagination.is-background .el-pager li:not(.disabled):hover {
		color: #007E3D !important;
	}

	/* 设置滚动条的样式 */
	::-webkit-scrollbar {
		width: 0px;
	}
</style>
