function addZero(val) {
	if (val < 10) {
		return "0" + val;
	} else {
		return val;
	}
};
export default {
	install(Vue) {
		Vue.filter('TimeFormat', function(value) {
			if (value) {
				let time = ""
				let myDate = new Date();
				let itemDate = new Date(value);

				if (myDate.getFullYear() == itemDate.getFullYear() && myDate.getMonth() == itemDate
					.getMonth() && myDate.getDate() ==
					itemDate.getDate()) {

					time = value.split(" ")[1].split(":");
					time.splice(2, 1);
					time = time.join(":");
				} else if (myDate.getFullYear() == itemDate.getFullYear() && myDate.getMonth() == itemDate
					.getMonth() && myDate.getDate() ==
					(itemDate.getDate() + 1)) {
					time = "昨日";
				} else {
					time = value.split(" ")[0];
				}
				return time
			} else {
				return '--'
			}
		})
		Vue.filter('styleFormat', function(value) {
				if (value) {
					let sty = ""
					Object.keys(value).map((key) => {
						sty += key + ':' + value[key] + ';'
					})
					return sty
				} else {
					return ""
				}
			}),
			Vue.filter("formatTime", function(value, type) {
				var dataTime = "";
				if (value) {
					var data = new Date(value);
					// data.setTime(value); 
					var year = data.getFullYear();
					var month = addZero(data.getMonth() + 1);
					var day = addZero(data.getDate());
					var hour = addZero(data.getHours());
					var minute = addZero(data.getMinutes());
					var second = addZero(data.getSeconds());
					if (type == "YMD") {
						dataTime = year + "-" + month + "-" + day;
					} else if (type == "YMDHMS") {
						dataTime = year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
					} else if (type == "HMS") {
						dataTime = hour + ":" + minute + ":" + second;
					} else if (type == "YM") {
						dataTime = year + "-" + month;
					}
				}
				return dataTime;
			})
	}
}
