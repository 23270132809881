import {
	baseInfo
} from "@/api/user.js"
import {
	setCookies,
	getCookies,
	removeCookies
} from "@/utils/cookies.js"
const state = {
	userInfo: JSON.parse(getCookies('userInfo')) || {
		id: 0,
		images: "",
		last_ll: "",
		last_login_time: "",
		last_update_pass_time: "",
		login_pass: "",
		nick_name: "未登录",
		pay_pass: "",
		phone: "",
		status: 1,
		type: 1,
		user_label: "",
		whats_app_user: "",
		wx_num: ""
	},
	token: getCookies('token') || false,
	categoryList: false,
	seleCategory: false,
	headShow: true,
	classifyShow:true,
	categoryType:0,
	searchData:false,
	userCenterType:7,
}

const getters = {

}

const mutations = {
	setUserCenterType(state, data) {
		state.userCenterType = data
	},
	setSearchData(state, data) {
		state.searchData = data
	},
	setCategoryType(state, data) {
		state.categoryType = data
	},
	setClassifyShow(state, data) {
		state.classifyShow = data
	},
	setHeadShow(state, data) {
		state.headShow = data
	},
	setCategoryList(state, data) {
		state.categoryList = data
	},
	setSeleCategory(state, {
		id,
		childrenId,
		type
	}) {
		let data = JSON.parse(JSON.stringify(state.seleCategory))
		// console.log("data 切换类目进来了")
		// console.log(data)
		// console.log(state.categoryList)
		// console.log(id)
		// console.log(childrenId)
		// console.log(type)
		if (id) {
			state.categoryList.map((item) => {
				if (item.base.id == id) {
					data = JSON.parse(JSON.stringify(item))
					data.id = item.base.id
				}
			})
		} else {
			if (childrenId && childrenId != -1) {
				state.categoryList.map((item) => {
					item.children.map((items) => {
						if (items.id == childrenId) {
							data = JSON.parse(JSON.stringify(item))
							data.id = item.base.id
						}
					})
				})
			} else {
				data = state.categoryList[0]
				data.id = state.categoryList[0].base.id
			}
		}
		data.children.map((item, index) => {
			if (childrenId) {
				if (item.id == childrenId) {
					item.checked = true
					data.childrenId = childrenId
				} else {
					item.checked = false
				}
			} else if (index == 0) {
				item.checked = true
				data.childrenId = item.id
			} else {
				item.checked = false
			}
		})
		if (childrenId == -1) {
			data.childrenId = -1
		}
		data.isRoll = false
		if (type) {
			data.isRoll = type
		}
		state.seleCategory = data
	},
	setUserInfo(state, data) {
		if (state.userInfo) {
			state.userInfo = Object.assign({}, state.userInfo, data)
		} else {
			state.userInfo = data
		}
		setCookies('userInfo', JSON.stringify(state.userInfo))
	},
	removeUserInfo(state) {
		state.userInfo = {
			create_time: "",
			id: 0,
			images: "",
			last_ll: "",
			last_login_time: "",
			last_update_pass_time: "",
			login_pass: "",
			nick_name: "未登录",
			pay_pass: "",
			phone: "",
			status: 1,
			type: 1,
			user_label: "",
			whats_app_user: "",
			wx_num: ""
		}
		removeCookies('userInfo')
	},
	setToken(state, data) {
		setCookies('token', data)
		state.token = data
	},
	removeToken(state) {
		state.token = false
		removeCookies('token')
	}
}

const actions = {
	async getUserInfo({
		commit
	}) {
		console.log(100000000000000)
		let {
			data
		} = await baseInfo()
		delete data.token
		commit('setUserInfo', data)
		return {
			data
		}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
