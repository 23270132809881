import fetch from '@/utils/fetch.js';

//获取城市以及对应的仓库
export function storehouseCityList(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/storehouse/city/list",
		method: 'POST',
		data
	})
}
//获取仓库对应的运费
export function cityDelivery(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/storehouse/city/delivery",
		method: 'POST',
		data
	})
}