import fetch from '@/utils/fetch.js';

//添加到购物车
export function add(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/cart/add",
		method: 'POST',
		data
	})
}


//删除购物车商品
export function cartDelete(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/cart/del",
		method: 'POST',
		data
	})
}

//获取购物车商品
export function cartAll(params) {
	let data = Object.assign({}, params);
	return fetch({
		type: "/order/cart/list",
		method: 'POST',
		data
	})
}