import {setCookies,getCookies} from "@/utils/cookies.js"
const state =  {
		lang:getCookies('lang') || 'en'
    }

const getters = {

}

const mutations = {
	setLang(state, data){
		setCookies('lang',data)
		state.lang=data
	},
}

const actions = {
	
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
