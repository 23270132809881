import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import publi from './utils/publi';
import i18n from './utils/i18n'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



// // rem等比适配配置文件
// // 基准大小
// const baseSize = 16
// // 设置 rem 函数
// function setRem () {
//   // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
//   const scale = document.documentElement.clientWidth / 1920
//   // 设置页面根节点字体大小（“Math.min(scale, 2)” 指最高放大比例为2，可根据实际业务需求调整）
//   document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
// }
// // 初始化
// setRem()
// // 改变窗口大小时重新设置 rem
// window.onresize = function () {
//   setRem()
// }



console.log('store')
console.log(store)
/* 引入全局过滤器 */
import Filter from './utils/filters'
Vue.use(Filter)
Vue.config.productionTip = false
Vue.use(i18n, store);
Vue.use(publi, store);
Vue.use(ElementUI);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
