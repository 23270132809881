import zh from "@/lang/zh.js"
import en from "@/lang/en.js"
import vie from "@/lang/vie.js"
export default {
	install(Vue, options) {
		let i18n = {
			"zh": {
				...zh
			},
			"en": {
				...en
			},
			"vie": {
				...vie
			}
		}
		this.i18n = i18n
		if (Vue) {
			Vue.prototype.i18n = (keyOne, keyTwo) => {
				if (keyTwo) {
					return i18n[options.state.i18n.lang][keyOne][keyTwo]
				} else {
					return i18n[options.state.i18n.lang][keyOne]
				}
			}
		} else {
			return i18n
		}

	}
}
