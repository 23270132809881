<template>
	<div id="headTop">
		<div class="info">
			<div class="inner">
				<div class="infoList left">
					<div class="item itemAc" @click.stop="$router.push('/')">
						<img src="@/assets/headTop/home.png" class="home" alt="">{{i18n('02')}}
					</div>
					<div class="item" v-if="seleLoca" @click="seleCityOpen">
						<img src="@/assets/headTop/location.png" class="location" alt="">
						{{lang=='zh'?seleLoca.base.zh_name:lang=='en'?seleLoca.base.en_name:seleLoca.base.vi_name}}<img
							src="@/assets/headTop/xl.png" class="xl" alt="">
					</div>
					<div class="item zc" v-if="seleLoca">
						{{lang=='zh'?seleLoca.storehouses[0].zh_name:lang=='en'?seleLoca.storehouses[0].en_name:seleLoca.storehouses[0].vi_name}}
					</div>
					<div class="item" v-if="!seleLoca" @click="seleCityOpen">
						{{i18n('37')}}
					</div>
				</div>
				<div class="infoList right">
					<div class="item itemAc" @click="$router.push('/loginRegistration?type=1')" v-if="!token">
						{{i18n('01')}}
					</div>
					<div class="item itemAc" v-if="token">
						<img :src="userInfo.avatar" alt="" class="avatar"> {{userInfo.nick_name}}
						<img src="@/assets/headTop/xl.png" class="xl" alt="">
						<div class="posi">
							<div class="x" @click="userCenterUrl(4,'/coupon')">
								{{i18n('22')}}
							</div>
							<div class="x" @click="userCenterUrl(3,'/recharge')">
								{{i18n('38')}}
							</div>
							<!-- <div class="x" @click="userCenterUrl(1,'/invitation')">
								{{i18n('39')}}
							</div> -->
							<div class="x" style="margin-top: 10px;opacity: .6;" @click="signOutOpen">
								{{i18n('40')}}
							</div>
						</div>
					</div>
					<div class="item itemAc" @click="userCenterUrl(2,'/myOrder')">
						{{i18n('33')}}
					</div>
					<div class="item itemAc" @click="$router.push('/cart')">
						{{i18n('29')}}
					</div>
					<div class="item itemAc" @click="$router.push('/about')">
						{{i18n('27')}}
					</div>
					<!-- <div class="item itemAc" @click="$router.push('/download')">
						<img src="@/assets/headTop/xz.png" class="xz" alt="">{{i18n('35')}}
					</div> -->
					<div class="item">
						{{lang=='zh'?'中文':lang=='en'?'English':'Tiếng Việt'}}<img src="@/assets/headTop/xl.png" class="xl" alt="">
						<div class="posi">
							<div :class="lang=='zh'?'x xAc':'x'" @click='langChang("zh")'>
								中文
							</div>
							<div :class="lang=='en'?'x xAc':'x'" @click='langChang("en")'>
								English
							</div>
							<div :class="lang=='vie'?'x xAc':'x'" @click='langChang("vie")'>
								Tiếng Việt
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<seleCity ref="seleCity"></seleCity>
		<signOut ref="signOut"></signOut>
	</div>
</template>

<script>
	import signOut from "./signOut.vue"
	import seleCity from "../seleCity.vue"
	export default {
		name: "headTop",
		components: {
			seleCity,
			signOut
		},
		data() {
			return {
				searchData: ""
			}
		},
		computed: {
			headShow() {
				return this.$store.state.user.headShow
			},
			token() {
				return this.$store.state.user.token
			},
			userInfo() {
				return this.$store.state.user.userInfo
			},
			lang() {
				return this.$store.state.i18n.lang
			},
			seleLoca() {
				return this.$store.state.serviceArea.seleLoca
			},
		},
		methods: {
			userCenterUrl(type,url){
				this.$store.commit("user/setUserCenterType", type)
				this.$router.push(url)
			},
			windowOpen(url) {
				window.open(url, '_blank')
			},
			search() {
				this.$store.commit("user/setSearchData", this.searchData)
				if (this.searchData != '' && this.$route.path != '/searchResult') {
					this.$router.push('/searchResult')
				}
			},
			langChang(data) {
				this.$store.commit('i18n/setLang', data)
			},
			signOutOpen(){
				this.$refs.signOut.open()
			},
			seleCityOpen(){
				this.$refs.seleCity.open()
			}
		},
		mounted() {
			if(!this.seleLoca){
				this.$refs.seleCity.open()
			}
		},
	};
</script>

<style scoped="scoped">
	.avatar{
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}
	.posi {
		position: absolute;
		left: 50%;
		top: 32px;
		width: 126px;
		background: #FFFFFF;
		box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
		padding: 13px;
		box-sizing: border-box;
		display: none;
		margin-left: -63px;
	}

	.posi .x {
		width: 100px;
		height: 32px;
		text-align: center;
		line-height: 32px;
		font-size: 16px;
		color: #1D2129;
	}

	.posi .xAc,
	.posi .x:hover {
		background: #F2F3F5;
	}

	#headTop {
		position: relative;
		
		/* min-height: 32px; */
		/* background-image: linear-gradient(180deg, #56AB7B 0%, #42BB5B 100%); */
	}

	.info {
		background: rgba(0, 0, 0, .3);
		width: 100%;
		height: 32px;
		
		position: absolute;
		left: 0;
		top: 0;
		z-index: 40;
	}
	.info .inner{
		width: 1300px;
		height: 32px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 50px;
		box-sizing: border-box;
	}
	.infoList {
		display: flex;
		align-items: center;
	}

	.infoList .item {
		display: flex;
		align-items: center;
		margin: 0 12px;
		font-size: 14px;
		color: #FFFFFF;
		cursor: pointer;
		position: relative;
		height: 32px;
	}

	.infoList .item:hover .posi {
		display: block;
	}

	.infoList .itemAc::after {
		content: "";
		width: 1px;
		height: 10px;
		position: absolute;
		right: -10px;
		top: 50%;
		margin-top: -5px;
		background: #fff;
	}

	.infoList .item img {
		margin-right: 4px;
	}

	.home {
		width: 14px;
		height: 14px;
	}

	.location {
		width: 14px;
		height: 14px;
	}

	.xl {
		width: 14px;
		height: 14px;
		margin-left: 4px;
	}

	.infoList .zc {
		padding: 0 15px;
		height: 18px;
		line-height: 18px;
		background-image: linear-gradient(180deg, #B5D58F 0%, #56AB7B 100%);
		border-radius: 9px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #FFFFFF;
		text-align: center;
		font-weight: 500;
		justify-content: center;
	}

	.xz {
		width: 8px;
		height: 12px;
	}
</style>
