import store from "@/store/index.js";
import axios from 'axios'
import i18n from "./i18n.js"
import router from '@/router' //引入router
import {
	getCookies
} from "@/utils/cookies.js"
import {
	Message
} from 'element-ui'
// 创建axios实例\
let BASE_URL = process.env.VUE_APP_BASE_URL
const service = axios.create({
	baseURL: BASE_URL,
	timeout: 600000, // 请求超时
})

// request拦截器
// 仅拦截参数配置状态响应
service.interceptors.request.use(config => {
	let token = getCookies('token')
	let XStorehouseId = store.state.serviceArea.seleLoca ? store.state.serviceArea.seleLoca.storehouses[0].storehouse_id : 1;
	if(token){
		let info = JSON.parse(getCookies('userInfo'))
		if(info.type==4){
			XStorehouseId = info.storehouse_id
		}
	}
	// config.headers['Content-Type'] = 'multipart/form-data';
	config.headers['Content-Type'] = 'application/json';
	config.headers['Authorization'] = token;
	config.headers['X-Storehouse-Id'] = XStorehouseId;
	return config
}, error => {
	// Do something with request error
	Promise.reject(error)
})
// 仅拦截异常状态响应
service.interceptors.response.use(response => {
	// let lang = getCookies('lang')
	if (response.status == 200) {
		if (response.data.code == 200) {
			return response.data
		} else if (response.data.code == 401 || response.data.code == 4007 || response.data.code == 4008 ||
			response.data.code == 4014 || response.data.code == 4019 || response.data.code == 4022) {
			//登陆
			store.commit("user/removeUserInfo")
			store.commit("user/removeToken")
			// store.commit("user/setGoLogin", true)
			router.push({
				path: 'loginRegistration?type=1'
			})
		} else {
			let i18nObj = i18n.install()
			i18nObj = i18nObj[store.state.i18n.lang]
			Message.error(i18nObj[response.data.code]);
			return Promise.reject(response.data.code)
		}

	}
}, error => {
	const {
		response
	} = error
	// 后端有返回的情况
	if (response) {
		//服务出错
		Message.error('未知错误');
		return Promise.reject(error.response)
	} else {
		/* 服务器没有返回结果 */
		/* 两种情况 无服务器崩了 或者 断网了 */
		if (!window.navigator.onLine) {
			// 断网处理
			// $message({
			// 	message: '没有网络',
			// 	type: 'warning'
			// });
			// router.history.push('/offLine')
		}
		return Promise.reject(error)
	}
});


export default function fetch(params) {
	let {
		// url,
		method,
		data,
		type
	} = params;
	// let data = ""

	// if(method=='get'){
	// 	Object.keys(params.data).forEach((item, index) => {
	// 	    index == 0 ? data = '?' + item + '=' + params.data[item] : data = data + '&' + item + '=' + params.data[item];
	// 	})
	// }
	return new Promise((resolve, reject) => {
		service({
			method,
			url: type,
			data
		}).then(res => { //axios返回的是一个promise对象
			resolve(res) //resolve在promise执行器内部
		}).catch(err => {
			reject(err)
			console.log(err, '异常')
		})
	});
}
