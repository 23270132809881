import Cookies from 'js-cookie'





export default {
	install(Vue, store) {
		Vue.prototype.documentHeight = document.documentElement.clientHeight || document.body.clientHeight;
		
		//验证手机号
		Vue.prototype.validateTel = (value) => {
			const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			if (value == '' || value == undefined || value == null) {
				return false
			} else {
				if ((!reg.test(value)) && value != '') {
					return false
				} else {
					return true
				}
			}
		};
		Vue.prototype.setCookies=(name, data)=> {
			Cookies.set(name, data, {
				expires: 7
			})
		}
		
		Vue.prototype.getCookies=(name)=> {
			 if(Cookies.get(name)==undefined){
				 return false
			 }else{
				 return Cookies.get(name)
			 }
			
		}
		
		Vue.prototype.removeCookies=(name)=> {
			Cookies.remove(name)
		}
		Vue.prototype.OSSImg='https://yeahfresh-1303169178.cos.accelerate.myqcloud.com/'
	}
}
