import Cookies from 'js-cookie'

export function setCookies(name, data) {
	Cookies.set(name, data, {
		expires: 7
	})
}

export function getCookies(name) {
	if (Cookies.get(name) == undefined) {
		return false
	} else {
		return Cookies.get(name)
	}

}

export function removeCookies(name) {
	Cookies.remove(name)
}
